import {del, get, post, put,} from "../../utils/fetch";
import {PetAttributes, RequestState} from "../../constants/constants";
import {selectUserEmrLinks} from "./UserEmrLink";
import {selectAllPets} from "./Pet";
import _ from "lodash";
import dayjs from "dayjs";


// ===========================
// HELPERS
// ===========================

const normalizePatientDetails = (patientDetails) => {
    patientDetails.hair = patientDetails.color;
    patientDetails.weight = parseInt(patientDetails.latestWeight);
    patientDetails.birthdate = dayjs(patientDetails.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    patientDetails.specie = (() => {
        switch (patientDetails.species?.toLowerCase()) {
            case 'canine':
                return 'dog';
            case 'feline':
                return 'cat';
            default:
                return patientDetails?.species;
        }
    })();
    return patientDetails;
}

const getDifferences = (current, newPatient) => {
    return PetAttributes.filter(attribute => {
        return newPatient[attribute] &&
            current[attribute]?.toString()?.toLowerCase() !== newPatient[attribute]?.toString()?.toLowerCase();
    })
}

// ===========================
// ACTIONS
// ===========================
export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENT_DETAILS = 'GET_PATIENT_DETAILS';
export const LIST_PET_USER_EMR_LINKS = 'LIST_PET_USER_EMR_LINKS';
export const FETCH_PET_USER_EMR_LINK = 'FETCH_PET_USER_EMR_LINK';
export const CREATE_PET_USER_EMR_LINK = 'CREATE_PET_USER_EMR_LINK';
export const UPDATE_PET_USER_EMR_LINK = 'UPDATE_PET_USER_EMR_LINK';
export const DELETE_PET_USER_EMR_LINK = 'DELETE_PET_USER_EMR_LINK';
export const SYNC_PETS = 'SYNC_PETS';

// ===========================
// SELECTORS
// ===========================
export const selectPetUserEmrLinks = (state) => state.models.petUserEmrLinks;
export const selectPetUserEmrLink = (state, petId) => _.find(state.models.petUserEmrLinks, emrLink => emrLink.petId === petId);

// ===========================
// MODEL
// ===========================
const PetUserEmrLink = {
    actions: {

        listLinks: (params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/pet-user-emr-links",
                params: {
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: LIST_PET_USER_EMR_LINKS,
            }).then(response => response.rows)
        },

        syncPets: (userEmrLinkId) => async (dispatch, getState) => {
            return get({
                url: "/api/pet-user-emr-links/sync-pets/:id",
                params: {
                    id: userEmrLinkId,
                },
                dispatch,
                withAuthHeaders: true,
                action: SYNC_PETS,
            }).then(response => response)
        },

        fetchLink: (id, params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/pet-user-emr-links/:id",
                params: {
                    id,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_PET_USER_EMR_LINK,
            }).then(response => response)
        },

        updateLink: (id, body, params = {}) => async (dispatch, getState) => {
            return put({
                url: "/api/pet-user-emr-links/:id",
                params: {
                    id,
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: UPDATE_PET_USER_EMR_LINK,
            }).then(response => response)
        },

        createLink: (body, params = {}) => async (dispatch, getState) => {
            return post({
                url: "/api/pet-user-emr-links",
                params: {
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: CREATE_PET_USER_EMR_LINK,
            }).then(response => response)
        },

        deleteLink: (id, params = {}) => async (dispatch, getState) => {
            return del({
                url: "/api/pet-user-emr-links/:id",
                params: {
                    id,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: DELETE_PET_USER_EMR_LINK,
            }).then(response => response)
        },


        getPatients: (userEmrLinkId) => async (dispatch, getState) => {
            return get({
                url: "/api/pet-user-emr-links/get-patients/:id",
                params: {id: userEmrLinkId},
                dispatch,
                withAuthHeaders: true,
                action: GET_PATIENTS,
            })
        },

        getPatientDetails: (userEmrLinkId, patientId) => async (dispatch, getState) => {
            return get({
                url: "/api/pet-user-emr-links/get-patient/:id/:patientId",
                params: {id: userEmrLinkId, patientId},
                dispatch,
                withAuthHeaders: true,
                action: GET_PATIENT_DETAILS,
            })
        },

        searchPets: () => async (dispatch, getState) => {
            const userEmrLinks = selectUserEmrLinks(getState());

            if (_.isEmpty(userEmrLinks)) return null

            // const existingPets = selectAllPets(getState())
            // const petsList = []


            await Promise.all(
                userEmrLinks.map(async (userEmrLink) => {
                    dispatch(PetUserEmrLink.actions.syncPets(userEmrLink.id))

                    // const patientsResponse = await dispatch(PetUserEmrLink.actions.getPatients(userEmrLink.id));
                    // if (_.isEmpty(patientsResponse)) return;
                    // for (const patient of patientsResponse.patients) {
                    //     let patientDetails = await dispatch(PetUserEmrLink.actions.getPatientDetails(patient.userEmrLinkId, patient.patientId));
                    //     patientDetails = normalizePatientDetails(patientDetails);
                    //
                    //     const matchingPet = existingPets.find(
                    //         existingPet => existingPet.name?.toLowerCase() === patientDetails.name?.toLowerCase())
                    //
                    //     if (matchingPet) {
                    //         const differences = getDifferences(matchingPet, patientDetails);
                    //         if (_.isEmpty(differences)) return
                    //
                    //         //Add to list for user diff check
                    //         petsList.push({
                    //             type: "diff",
                    //             currentPetData: matchingPet,
                    //             newPetData: patientDetails,
                    //             userEmrLink,
                    //             diff: differences
                    //         })
                    //     } else {
                    //         //Add to list for new entry
                    //         petsList.push({type: "new", newPetData: patientDetails, userEmrLink});
                    //     }
                    // }
                })
            )

            // return petsList
            return true
        }
    },

    spec: {
        petUserEmrLinks: {},
    },

    modelReducer: (state, type, data, action, json) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case FETCH_PET_USER_EMR_LINK:
            case CREATE_PET_USER_EMR_LINK:
            case UPDATE_PET_USER_EMR_LINK:
                state = {
                    ...state,
                    petUserEmrLinks: {
                        ...state.pets,
                        [data.id]: data,
                    }
                }
                break;
            case LIST_PET_USER_EMR_LINKS:
                state = {
                    ...state,
                    petUserEmrLinks: _.keyBy(data, 'id')
                }
                break;
            default: break;
        }

        return state;
    }
}
export default PetUserEmrLink;
