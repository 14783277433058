import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {selectCurrentUser, selectCurrentUserId} from "../store/AppState";
import {Urls} from "../constants/constants";
import _ from "lodash";
import {isUserAdmin} from "../store/models/User";

const selector = createSelector(
    (state) => selectCurrentUserId(state),
    (state) => selectCurrentUser(state),
    (userId, user) => ({userId, user}))


export default function PrivateRoutes() {
    const {userId} = useSelector(state => selector(state))

    if (_.isNaN(userId)) {
        return <Navigate to={Urls.SIGNIN}/>
    } else {
        return <Outlet/>
    }
}


export function AdminRoutes() {
    const {user} = useSelector(state => selector(state))

    if (isUserAdmin(user)) {
        return <Outlet/>
    } else {
        return <Navigate to={Urls.NOT_FOUND}/>
    }
}
