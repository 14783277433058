import {ActionIcon, Button, Card, Flex, Group, Image, Paper, Stack, Text, ThemeIcon} from "@mantine/core";
import {
    IconEdit,
    IconGenderFemale,
    IconGenderMale,
    IconLoader,
    IconPdf,
    IconQuestionMark,
    IconTrash
} from "@tabler/icons-react";
import {getYearsMonths} from "../../utils/date";
import {PetPdf} from "./PetPdf";
import {PDFDownloadLink} from "@react-pdf/renderer";
import cx from "classnames";
import _ from "lodash";

const getDefaultAvatar = (type) => {
    switch (type?.toLowerCase()) {
        case "dog":
            return "/dog-placeholder.png"
        case "cat":
            return "/cat-placeholder.png"
        default:
            return null
    }
}
const SexIcon = ({sex}) => {
    switch (sex) {
        case "male":
            return <ThemeIcon><IconGenderMale/></ThemeIcon>
        case "female":
            return <ThemeIcon><IconGenderFemale/></ThemeIcon>
        default:
            return <ThemeIcon><IconQuestionMark/></ThemeIcon>
    }
}

const TraitBadge = ({label, data}) => {
    return (<Flex className={"trait-badge"} gap={0}>
        <Text c="gray.7" size="md ">{label}</Text>
        <Text fw="800" c="mp-orange.7" size="md">{data ?? "-"}</Text>
    </Flex>)
}

export const PetCard = ({pet, isPreview = false, onView, onEdit, onDelete, onOpenMasterProblemList}) => {

    const onEditClick = (e) => {
        e.stopPropagation(e);
        onEdit(pet);
    }

    const onDeleteClick = (e) => {
        e.stopPropagation(e);
        onDelete(pet);
    }

    const onOpenMasterProblemListClick = (e) => {
        e.stopPropagation(e);
        onOpenMasterProblemList(pet);
    }

    return (
        <Card className={cx("g-pet-card", {active: onView != null})} shadow="sm" radius="md" withBorder onClick={() => onView ? onView(pet) : null}>
            <Card.Section className={"card-header"}>
                {!_.isNil(getDefaultAvatar(pet.specie)) && <Image
                    src={getDefaultAvatar(pet.specie)}
                    height={120}
                    fit={"contain"}
                    alt={pet.specie}
                />}
            </Card.Section>

            <Card.Section>
                <Paper className={"card-primary"} shadow="xs" padding="lg" radius="lg">
                    <Group justify="space-between">
                        <Stack gap={"3"}>
                            <Text className={"pet-name"} size={"lg"} fw={800}>{pet.name}</Text>
                            <Text c={"grey.7"} size={"sm"}>{pet.breed}</Text>
                        </Stack>
                        <SexIcon sex={pet.sex?.toLowerCase()}/>
                    </Group>
                </Paper>
            </Card.Section>

            <Card.Section>
                <div className={"card-body"}>
                    <Group grow mb="lg">
                        <TraitBadge label="age" data={getYearsMonths(pet.birthdate)}/>
                        <TraitBadge label="color" data={pet.color}/>
                    </Group>
                    <Group grow mb="lg">
                        <TraitBadge label="weight" data={`${pet?.weight ?? '-'} lb`}/>
                        <TraitBadge label="hair" data={pet.hair}/>
                    </Group>
                </div>
            </Card.Section>
            {(!isPreview) && <Card.Section className={"card-footer"}>
                <Group justify={"space-between"}>
                    <Button size={"sm"} ml={"md"} mb={5} radius={"md"} color={"blue"} onClick={onOpenMasterProblemListClick}>Master Problem List</Button>
                    <Group justify={"right"} gap={"sm"}>
                        <DownloadPdfButton pet={pet} />
                        <ActionIcon className={"edit-btn"} radius={"md"}
                                    aria-label="Edit" onClick={onEditClick}>
                            <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon className={"delete-btn"} radius={"md"} mr={"sm"}
                                    aria-label="Delete" onClick={onDeleteClick}>
                            <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </Group>
            </Card.Section>}
        </Card>
    )

}

const DownloadPdfButton = ({pet}) => {
    const handleClick = (e) => {
        e.stopPropagation(e);
    }

    return (<PDFDownloadLink document={<PetPdf pet={pet} />} fileName={`${pet.name}.pdf`} onClick={handleClick}>
        {({ blob, url, loading, error }) =>
            loading
                ? <ActionIcon className={"pdf-btn"} radius={"md"}
                              aria-label="Loading">
                    <IconLoader style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
                : <ActionIcon className={"pdf-btn"} radius={"md"}
                              aria-label="Export PDF">
                    <IconPdf style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
        }
    </PDFDownloadLink>)
}