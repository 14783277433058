import _ from "lodash";
import {ActionIcon, Badge, Group, rem, Table, Text, Title} from "@mantine/core";
import dayjs from "dayjs";
import {
    IconCalendar,
    IconDeviceLaptop,
    IconEdit,
    IconEmergencyBed,
    IconHomeMove,
    IconMacro,
    IconMap2,
    IconQuestionMark,
    IconSearch,
    IconTrash
} from "@tabler/icons-react";

// "petUserEmrLinkId": 6,
//     "address": "251 N Amphlett Blvd. San Mateo, CA 94401 ",
//     "reason": "dev my paws",
//     "start": "2024-08-01T15:00:00.000Z",
//     "dropoffTime": null,
//     "duration": 60,
//     "isDaycare": false,
//     "isDropoff": false,
//     "isExam": true,
//     "isSurgery": false,
//     "isTelemedicine": false,
//     "name": "New Consult",

const getAppointmentBadge = (appt) => {
    const style = {width: rem(12), height: rem(12)}
    if (appt.isDaycare) {
        return <Badge leftSection={<IconHomeMove style={style}/>} variant={"filled"} color={"pink"}>Daycare</Badge>
    } else if (appt.isDropoff) {
        return <Badge leftSection={<IconMacro style={style}/>} variant={"filled"} color={"yellow"}>Dropoff</Badge>
    } else if (appt.isExam) {
        return <Badge leftSection={<IconSearch style={style}/>} variant={"filled"} color={"blue"}>Exam</Badge>
    } else if (appt.isSurgery) {
        return <Badge leftSection={<IconEmergencyBed style={style}/>} variant={"filled"}
                      color={"orange"}>Surgery</Badge>
    } else if (appt.isTelemedicine) {
        return <Badge leftSection={<IconDeviceLaptop style={style}/>} variant={"filled"}
                      color={"purple"}>Telemedicine</Badge>
    } else {
        return <Badge leftSection={<IconQuestionMark style={style}/>} variant={"filled"} color={"grey"}>Unknown</Badge>
    }
}

export const AppointmentCardsList = ({children, appts, pets, petUserEmrLinks, onEdit, onDelete}) => {

    const showPetColumn = pets.length > 1

    return (
        <div className={"g-appt-list"}>
            <Title mb={"lg"}>appointments</Title>

            <Table striped stickyHeader mb={"lg"} verticalSpacing="sm">
                <Table.Thead c={"white"} style={{backgroundColor: "var(--mantine-color-mp-orange-5)"}}>
                    <Table.Tr>
                        <Table.Th>Date</Table.Th>
                        <Table.Th>Type</Table.Th>
                        {showPetColumn && <Table.Th>Pet</Table.Th>}
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Reason</Table.Th>
                        <Table.Th>Address</Table.Th>
                        {/*<Table.Th ta={"right"} pr={"xl"}>Actions</Table.Th>*/}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {_.isEmpty(appts) ? <Table.Tr>
                            <Table.Td colSpan={6} ta={"center"}>No appointments found.</Table.Td>
                        </Table.Tr>
                        : _.map(appts, (appt, index) => {
                            const petUserEmrLink = _.find(petUserEmrLinks, link => link.id === appt.petUserEmrLinkId)
                            // if (showPetColumn && !petUserEmrLink) return null
                            const pet = _.find(pets, pet => pet.id === petUserEmrLink?.petId)
                            return <AppointmentRow key={index}
                                                   appt={appt}
                                                   pet={pet}
                                                   showPetColumn={showPetColumn}
                                                   onEdit={onEdit}
                                                   onDelete={onDelete}/>
                        })}
                </Table.Tbody>
            </Table>
            {children}
        </div>
    )
}

const AppointmentRow = ({appt, pet, onEdit, onDelete, showPetColumn}) => {
    return (
        <Table.Tr>
            <Table.Td w={rem(200)}>
                <Group gap={"sm"}>
                    <IconCalendar size={18} color={"grey"}/>
                    <Text size={"sm"}>{dayjs(appt.start ?? appt.dropoffTime).format("MM/DD/YYYY hh:mm A")}</Text>
                </Group>
            </Table.Td>
            <Table.Td w={rem(140)}>{getAppointmentBadge(appt)}</Table.Td>
            {showPetColumn && <Table.Td>{pet?.name}</Table.Td>}
            <Table.Td>{appt.name}</Table.Td>
            <Table.Td>
                {appt.reason}
            </Table.Td>
            <Table.Td>
                <Group gap={"sm"}>
                    <IconMap2 size={18} color={"grey"}/>
                    <a rel={"noreferrer"}
                       href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(appt.address)}`}
                       target={"_blank"}>{appt.address}</a>
                </Group>
            </Table.Td>
            {/*<Table.Td>*/}
            {/*    <Group justify={"right"} gap={"sm"}>*/}
                    {/*<ActionIcon className={"edit-btn"} radius={"md"}*/}
                    {/*            aria-label="Edit" onClick={() => onEdit(appt)}>*/}
                    {/*    <IconEdit style={{width: '70%', height: '70%'}} stroke={1.5}/>*/}
                    {/*</ActionIcon>*/}
                    {/*<ActionIcon className={"delete-btn"} radius={"md"} mr={"sm"}*/}
                    {/*            aria-label="Delete" onClick={() => onDelete(appt)}>*/}
                    {/*    <IconTrash style={{width: '70%', height: '70%'}} stroke={1.5}/>*/}
                    {/*</ActionIcon>*/}
                {/*</Group>*/}
            {/*</Table.Td>*/}
        </Table.Tr>
    )
}