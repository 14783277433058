import {mergeUrl} from './url';

import {Hosts, RequestState} from '../constants/constants';
import Storage from '../utils/storage';
import MPStorage from "../utils/storage";
import dayjs from "dayjs";
import User from "../store/models/User";

const {REQUEST, SUCCESS, FAIL, NOT_AUTHORIZED} = RequestState;


// ===========================
// HELPERS
// ===========================

export function fetchOptions(method = 'get', body, headers = {}) {
  return {
    method,
    headers: {
      ...(body ? {'Content-Type': 'application/json'} : {}),
      ...headers,
    },
    body: JSON.stringify(body),
  };
}

export function restUrl(url, params, host) {
  if (url.indexOf("http") !== -1) {
    return mergeUrl(url, params);
  }
  return mergeUrl(host + url, params);
}

export function isErrorResponse(code) {
  return code !== 200 && code !== 204 && code !== 201;
}

export function authHeaders() {
  const accessToken = MPStorage.getAccessToken();
  if (accessToken) {
    return {
      'X-Auth-Token': accessToken,
    }
  }
}

// ===========================
// FETCH FUNCTION
// ===========================
async function _fetch(args) {
  let {
    url, params, method, dispatch,
    action, body, headers = {}, host = Hosts.API, data, withAuthHeaders = false,
  } = args;

  //renew access token if expired
  if (withAuthHeaders) {
    const accessTokenExpires = Storage.getAccessTokenExpires()
    if (dayjs().isAfter(dayjs(accessTokenExpires))) {
      const success = await dispatch(User.actions.refreshToken())
      if (!success) return;
    }

    headers['Authorization'] = `Bearer ${Storage.getAccessToken()}`
  }

  url = restUrl(url, params, host);
  let options = fetchOptions(method, body, headers);

  // Disaptch Request
  if (dispatch && action)
    dispatch(request(action, url, params, body, data));

  // Do Fetch
  let response;
  try {
    response = await fetch(url, options);
  } catch (err) { // NETWORK ERROR
    if (typeof err === 'string') {
      console.log("URL is unreachable string: ", url, err);
      return Promise.reject({message: err});
    } else if (typeof err === 'object') {
      console.log("URL is unreachable object: ", url, err);
      return Promise.reject({message: err.message});
    }
    console.log(err);
    return Promise.reject({message: "Please try again later."});
  }

  let json = {}
  try {
    if (method !== 'delete' && response.status !== 204) {
      json = await response?.json();
    }
  } catch (e) { // JSON PARSE ERROR
    console.log('err: ' + e?.message)
  }

  if (isErrorResponse(response.status)) {
    console.log("API ERROR");
    console.log(json);
    console.log(response);
    let err = {
      message: json.response.details || json.response.message || 'Unknown Error',
      code: json.statusCode || response.status || 'Unknown Code',
    }

    if (dispatch && action)
      dispatch(error(action, err, url, params, data));

    return Promise.reject({...err, action});
  }

  if (dispatch && action)
    dispatch(receive(action, json, url, params, data));

  json.headers = headers;
  return Promise.resolve(json.response?.data ?? json);
}


// ===========================
// REQUST TYPES
// ===========================
export const get = (options) => _fetch({...options, method: 'get'});
export const post = (options) => _fetch({...options, method: 'post'});
export const put = (options) => _fetch({...options, method: 'put'});
export const del = (options) => _fetch({...options, method: 'delete'});


// ===========================
// RESPONSE TYPES
// ===========================
export function request(action, url, params, body, data = null) {
  return {
    type: action,
    url,
    params,
    body,
    result: REQUEST,
    fetchAt: Date.now(),
    data,
  };
}

export function receive(action, json, url, params, data = null) {
  return {
    type: action,
    json,
    url,
    params,
    result: SUCCESS,
    receivedAt: Date.now(),
    data
  }
}

export function error(action, error, url, params, data) {
  return {
    type: action,
    ...error,
    url,
    params,
    result: FAIL,
    receivedAt: Date.now(),
    data,
  }
}