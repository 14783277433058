import {PetCard} from "./PetCard";
import _ from "lodash";

export const PetCardsList = ({children, pets, onView, onEdit, onDelete,
                                 onOpenMasterProblemList}) => {

    return (
        <div className={"g-pet-cards-list"}>
            {_.map(pets, (pet, index) => {
                return <PetCard key={index} pet={pet} onView={onView} onEdit={onEdit} onDelete={onDelete} onOpenMasterProblemList={onOpenMasterProblemList}/>
            })}
            {children}
        </div>
    )
}