import {Badge, Container, Divider, Group, Paper, rem, SimpleGrid, Stack, Text, Title} from "@mantine/core";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import MedicalRecord, {selectMedicalRecordById} from "../../store/models/MedicalRecord";
import {BackButton} from "../../components/BackButton";
import Pet, {selectAllPets} from "../../store/models/Pet";
import PetUserEmrLink, {selectPetUserEmrLinks} from "../../store/models/PetUserEmrLink";
import {createSelector} from "reselect";
import _ from "lodash";
import {getMedicalRecordBadge} from "../../components/medical-record/MedicalRecordCardsList";
import {IconFile} from "@tabler/icons-react";
import dayjs from "dayjs";

const selector = createSelector(
    (state, medRecId) => selectMedicalRecordById(state, medRecId),
    (state) => selectAllPets(state),
    (state) => selectPetUserEmrLinks(state),
    (medicalRecord, pets, petUserEmrLinks) => {
        const petUserEmrLink = _.find(petUserEmrLinks, link => link.id === medicalRecord.petUserEmrLinkId)
        const pet = _.find(pets, pet => pet?.id === petUserEmrLink?.petId)
        return {
            medicalRecord,
            pet,
            petUserEmrLink
        }
    }
)

export const MedicalRecordDetailsPage = ({...props}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams()
    const medRecId = params.medRecId
    const {pet, medicalRecord, petUserEmrLink} = useSelector(state => selector(state, medRecId))

    useEffect(() => {
        (async () => {
            try {
                await Promise.all([
                    dispatch(Pet.actions.listPets()),
                    await dispatch(MedicalRecord.actions.fetchMedicalRecord(medRecId)),
                    dispatch(PetUserEmrLink.actions.listLinks())
                ])
            } catch (e) {
                console.log(e)
            }
        })()
    }, []);


    return (
        <Container className={"g-med-rec-details-page"}>
            <BackButton/>

            <Paper shadow={"md"} p={"xl"} radius={"lg"}>
                <Stack>
                    {pet?.name
                        ? <Title align={"center"}>{`${pet?.name}'s medical record`}</Title>
                        : <Title align={"center"}>{`medical record`}</Title>}
                    {medicalRecord?.date && <Text>{dayjs(medicalRecord?.date).format('MMMM D, YYYY')}</Text>}

                    <Stack gap={0} align={"center"}>
                        <Badge leftSection={<IconFile style={{width: rem(12), height: rem(12)}}/>} variant={"filled"} color={"blue"}>{medicalRecord?.category}</Badge>
                        <Text>{medicalRecord?.encounterType}</Text>
                    </Stack>

                    <Divider/>

                    {medicalRecord?.dischargeInstructions && <Text><b>Discharge instructions:</b> {medicalRecord?.dischargeInstructions}</Text>}
                    {medicalRecord?.medicalNote && <Text><b>Medical note:</b> {medicalRecord?.medicalNote}</Text>}

                    <Divider/>

                    <Text><b>Subjective:</b> {medicalRecord?.subjective}</Text>
                    <Divider/>
                    <Text><b>Objective:</b> {medicalRecord?.objective}</Text>
                    <Divider/>
                    <Text><b>Assessment:</b> {medicalRecord?.assessment}</Text>
                    <Divider/>
                    <Text><b>Plan:</b> {medicalRecord?.plan}</Text>
                    <Divider/>

                    <Text><b>Physical Exams</b></Text>
                    <Group>{medicalRecord?.physicalExam.map((exam, index) => {
                            return <Stack gap={"0"} key={index} align={"center"} c={"mp-orange.9"} w={150} bg={"mp-orange.1"} p={"sm"} rd={"md"}>
                                <Text size={"sm"}><b>{exam.label}</b></Text>
                                <Text size={"sm"}>{exam.value}</Text>
                            </Stack>
                        })}</Group>
                </Stack>

            </Paper>
        </Container>
    )

}