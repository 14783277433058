import _ from "lodash";
import {ActionIcon, Badge, Group, rem, Table, Title} from "@mantine/core";
import {IconFile, IconFolderOpen, IconTrash} from "@tabler/icons-react";
import React from "react";

export const getMedicalRecordBadge = (category) => {
    const style = {width: rem(12), height: rem(12)}
    switch (category) {
        case "General":
            return <Badge leftSection={<IconFile style={style}/>} variant={"filled"} color={"pink"}>{category}</Badge>
    }
}

export const MedicalRecordCardsList = ({children, medRecs, pets, petUserEmrLinks, onView, onDelete}) => {
    const showPetColumn = pets.length > 1
    return (
        <div className={"g-medRec-list"}>
            <Title mb={"lg"}>medical records</Title>

            <Table striped stickyHeader mb={"xl"} verticalSpacing="sm">
                <Table.Thead c={"white"} style={{backgroundColor: "var(--mantine-color-mp-orange-5)"}}>
                    <Table.Tr>
                        <Table.Th>Date</Table.Th>
                        <Table.Th>Type</Table.Th>
                        {showPetColumn && <Table.Th>Pet</Table.Th>}
                        <Table.Th>Title</Table.Th>
                        <Table.Th ta={"right"} pr={"xl"}>Actions</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {_.isEmpty(medRecs) ? <Table.Tr>
                            <Table.Td colSpan={6} ta={"center"}>No medical records found.</Table.Td>
                        </Table.Tr>
                        : _.map(medRecs, (medRec, index) => {
                            const petUserEmrLink = _.find(petUserEmrLinks, link => link.id === medRec.petUserEmrLinkId)
                            // if (showPetColumn && !petUserEmrLink) return null
                            const pet = _.find(pets, pet => pet.id === petUserEmrLink?.petId)
                            return <MedicalRecordRow key={index}
                                                     medRec={medRec}
                                                     pet={pet}
                                                     showPetColumn={showPetColumn}
                                                     onView={onView}
                                                     onDelete={onDelete}/>
                        })}
                </Table.Tbody>
            </Table>
            {children}
        </div>
    )
}

const MedicalRecordRow = ({medRec, pet, onView, onDelete, showPetColumn}) => {
    return (
        <Table.Tr>
            <Table.Td>{medRec.date ?? '-'}</Table.Td>
            <Table.Td w={rem(140)}><Badge leftSection={<IconFile style={{width: rem(12), height: rem(12)}}/>} variant={"filled"} color={"blue"}>{medRec?.category ?? '-'}</Badge>
            </Table.Td>
            {showPetColumn && <Table.Td>{pet?.name ?? '-'}</Table.Td>}
            <Table.Td>{medRec.title}</Table.Td>
            <Table.Td>
                <Group justify={"right"} gap={"sm"}>
                    <ActionIcon className={"view-btn"} radius={"md"}
                                aria-label="View" onClick={() => onView(medRec)}>
                        <IconFolderOpen style={{width: '70%', height: '70%'}} stroke={1.5}/>
                    </ActionIcon>
                    {/*<ActionIcon className={"delete-btn"} radius={"md"} mr={"sm"}*/}
                    {/*            aria-label="Delete" onClick={() => onDelete(medRec)}>*/}
                    {/*    <IconTrash style={{width: '70%', height: '70%'}} stroke={1.5}/>*/}
                    {/*</ActionIcon>*/}
                </Group>
            </Table.Td>
        </Table.Tr>
    )
}