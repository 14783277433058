import {IconArrowLeft} from "@tabler/icons-react";
import {Group, Text} from "@mantine/core";
import React from "react";
import {useNavigate} from "react-router-dom";

export const BackButton = () => {
    const navigate = useNavigate();

    const onBack = () => {
        navigate(-1)
    }
    return (<Group onClick={onBack} className={"g-back-btn"} gap={6}>
        <IconArrowLeft size={"18"}/>
        <Text size={"sm"}>Back</Text>
    </Group>)
}