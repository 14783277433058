
export const mantineColors = [
  '#ffeee4',
  '#ffdecf',
  '#fabba0',
  '#f6976e',
  '#f27743',
  '#f06327',
  '#f05817',
  '#d5490b',
  '#be3e06',
  '#a73300'
];

export const openColors = [
  "blue", "violet", "pink", "red", "orange", "yellow", "green", "indigo", "gray"
]