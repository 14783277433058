import React, {useEffect, useState} from "react";
import Pet from "../../store/models/Pet";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {
    Button,
    Container,
    Group,
    Modal,
    NativeSelect,
    NumberInput,
    Paper,
    Stack,
    Text,
    TextInput,
    Title
} from "@mantine/core";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "@mantine/form";
import {Urls} from "../../constants/constants";
import {DateInput} from '@mantine/dates';
import dayjs from "dayjs";
import {selectCurrentUserId} from "../../store/AppState";
import _ from "lodash";
import {BackButton} from "../../components/BackButton";


const selector = createSelector(
    (state) => selectCurrentUserId(state),
    (userId) => ({
        userId
    })
)

// 09/01/2022
export const EditPetPage = ({...props}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams()
    const currentPetId = params.petId
    const isEditing = !_.isNil(currentPetId)

    const {userId} = useSelector(state => selector(state))
    const [errorMessage, setErrorMessage] = useState(null)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const form = useForm({
        initialValues: {
            "name": '',
            "specie": '',
            "sex": '',
            "breed": '',
            "weight": 10,
            "hair": '',
            "birthdate": ''
        },

        validate: {
            specie: (value) => (value === 'Select' ? 'Please select a specie' : null),
            sex: (value) => (value === 'Select' ? 'Please select a gender' : null),
        },
    });

    useEffect(() => {
        (async () => {

            if (isEditing) {
                const petResponse = await dispatch(Pet.actions.fetchPet(currentPetId))
                const birthdate = dayjs(petResponse.birthdate, 'DD-MM-YYYY')
                if (birthdate.isValid()) {
                    petResponse.birthdate = dayjs(birthdate).toDate()
                } else {
                    petResponse.birthdate = '';
                }

                petResponse.specie = petResponse.specie?.toLowerCase()
                petResponse.sex = petResponse.sex?.toLowerCase()
                petResponse.hair = petResponse.hair ?? ''
                form.setValues(petResponse)

                if (!birthdate.isValid()) {
                    form.setFieldError('birthdate', 'Invalid date')
                }
            }
        })()
    }, []);

    const onSubmit = async (data) => {
        const {name, specie, sex, breed, weight, hair, birthdate} = data
        setErrorMessage(null)

        const body = {
            userId, name, specie, sex, breed, weight, hair,
            birthdate: dayjs(birthdate).format('DD-MM-YYYY')
        }

        try {
            if (isEditing) {
                await dispatch(Pet.actions.updatePet(currentPetId, body));
            } else {
                await dispatch(Pet.actions.createPet(body));
            }
            navigate(Urls.HOME);
        } catch (e) {
            const message = e?.message?.toLowerCase()
            setErrorMessage(e?.message)
        }
    };

    const onDelete = () => {
        setShowConfirmDelete(true)
    }

    const onConfirmDelete = async () => {
        try {
            await dispatch(Pet.actions.deletePet(currentPetId));
            navigate(Urls.HOME);
        } catch (e) {
            setErrorMessage(e.message)
        }
    }

    const onCloseModal = () => {
        setShowConfirmDelete(false)
    }

    return (<Container className={"g-edit-pet-page"} size={420}>
        <BackButton/>
        <Title align={"center"} mb={"md"}>
            {isEditing ? "edit my pet" : "add a new pet"}
        </Title>

        <form onSubmit={form.onSubmit(onSubmit)}>
            <Paper shadow={"md"} p={"xl"} radius={"lg"}>
                {errorMessage && <Text c={"red"} size={"sm"} mb={"sm"}>{errorMessage}</Text>}
                <TextInput
                    label="Name"
                    mb={"sm"}
                    required
                    {...form.getInputProps('name')}/>

                <NativeSelect
                    label="Specie"
                    value={form.values.specie}
                    onChange={(value) => form.setFieldValue('specie', value)}
                    data={['select', 'dog', 'cat', 'rabbit', 'turtle', 'fish']}
                    mb={"sm"}
                    required
                    {...form.getInputProps('specie')}/>

                <NativeSelect
                    label="Gender"
                    value={form.values.sex}
                    onChange={(value) => form.setFieldValue('sex', value)}
                    data={['select', 'female', 'male']}
                    mb={"sm"}
                    required
                    {...form.getInputProps('sex')}/>
                <DateInput
                    label="Birthdate"
                    mb={"sm"}
                    valueFormat={"DD/MM/YYYY"}
                    defaultLevel={"year"}
                    required
                    {...form.getInputProps('birthdate')}/>
                <TextInput
                    label="Breed"
                    mb={"sm"}
                    {...form.getInputProps('breed')}/>
                <NumberInput
                    label="Weight (lb)"
                    mb={"sm"}
                    {...form.getInputProps('weight')}/>
                <TextInput
                    label="Hair"
                    {...form.getInputProps('hair')}/>

                <Group mt="xl" justify={isEditing ? "space-between" : "center"}>
                    <Button size={"lg"} type={"submit"} radius={"lg"}>
                        {isEditing ? "update" : "create"}
                    </Button>
                    {isEditing && <Button color={"gray"}
                                          size={"lg"} radius={"lg"}
                                          onClick={onDelete}>
                        delete
                    </Button>}
                </Group>
            </Paper>
        </form>

        <Modal opened={showConfirmDelete} withCloseButton={false} centered
               onClose={onCloseModal} radius="md">
            <Stack align={"center"} mt={"xl"} mb={"lg"}>
                <Text size={"lg"} mb={"lg"}>Are you sure you want to delete {form.values?.name}'s profile?</Text>

                <Group>
                    <Button  size="lg" color="gray"
                            radius={"lg"} mr={"xl"} onClick={onCloseModal}>Cancel</Button>
                    <Button size="lg" color="red"
                            radius={"lg"} onClick={onConfirmDelete}>Confirm</Button>
                </Group>
            </Stack>
        </Modal>
    </Container>)
}