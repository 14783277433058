import {del, get, post, put} from "../../utils/fetch";
import {RequestState} from "../../constants/constants";
import _ from "lodash";
import {selectPetUserEmrLinks} from "./PetUserEmrLink";

const LIST_MEDICAL_RECORDS = 'LIST_MEDICAL_RECORDS';
const FETCH_MEDICAL_RECORD = 'FETCH_MEDICAL_RECORD';
const UPDATE_MEDICAL_RECORD = 'UPDATE_MEDICAL_RECORD';
const CREATE_MEDICAL_RECORD = 'CREATE_MEDICAL_RECORD';
const DELETE_MEDICAL_RECORD = 'DELETE_MEDICAL_RECORD';
const GET_MEDICAL_RECORDS_FROM_EMR = 'GET_MEDICAL_RECORDS_FROM_EMR';
const FETCH_MASTER_PROBLEM_LIST = 'FETCH_MASTER_PROBLEM_LIST';

export const selectMedicalRecords = (state) => Object.values(state.models.medicalRecords);
export const selectMedicalRecordsSize = (state) => state.models.medicalRecordsSize;
export const selectMedicalRecordById = (state, id) => state.models.medicalRecords[id];
export const selectMasterProblemList = (state, petId) => {
    const petProblems = state.models.masterProblemList[petId];
    //return problem with the latest end_date
    return _.maxBy(petProblems, 'end_date');
}

export const selectPetMedicalRecords = (state, petId) => {
    const petUserEmrLink = _.find(selectPetUserEmrLinks(state), emrLink =>
        emrLink?.petId?.toString() === petId?.toString());
    return _.filter(selectMedicalRecords(state), medRec => {
        return medRec.petUserEmrLinkId === petUserEmrLink?.id
    });
}

const MedicalRecord = {
    actions: {
        listMedicalRecords: (params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/medical-records",
                params: {
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: LIST_MEDICAL_RECORDS,
            }).then(response => response)
        },

        fetchMedicalRecord: (medicalRecordId, params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/medical-records/:medicalRecordId",
                params: {
                    medicalRecordId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_MEDICAL_RECORD,
            }).then(response => response)
        },

        updateMedicalRecord: (medicalRecordId, body, params = {}) => async (dispatch, getState) => {
            return put({
                url: "/api/medical-records/:medicalRecordId",
                params: {
                    medicalRecordId,
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: UPDATE_MEDICAL_RECORD,
            }).then(response => response)
        },

        createMedicalRecord: (body, params = {}) => async (dispatch, getState) => {
            return post({
                url: "/api/medical-records",
                params: {
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: CREATE_MEDICAL_RECORD,
            }).then(response => response)
        },

        deleteMedicalRecord: (medicalRecordId, params = {}) => async (dispatch, getState) => {
            return del({
                url: "/api/medical-records/:medicalRecordId",
                params: {
                    medicalRecordId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: DELETE_MEDICAL_RECORD,
            }).then(response => response)
        },

        getMedicalRecordsFromEmr: (petId) => async (dispatch, getState) => {
            return get({
                url: "/api//medical-records/sync-medical-records-from-emrs",
                params: {},
                dispatch,
                withAuthHeaders: true,
                action: GET_MEDICAL_RECORDS_FROM_EMR,
            }).then(response => response)
        },

        fetchMasterProblemList: (petId) => async (dispatch, getState) => {
            return get({
                url: "/api/medical-records/master-problem-list/:petId",
                params: {
                    petId
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_MASTER_PROBLEM_LIST,
            }).then(response => response)
        },

        syncMedicalRecords: (petId) => async (dispatch, getState) => {
            // const existingMedicalRecords = await dispatch(MedicalRecord.actions.listMedicalRecords())
            // const medicalRecordsFromEmr = await dispatch(MedicalRecord.actions.getMedicalRecordsFromEmr(petId))

        }
    },

    spec: {
        medicalRecords: {},
        masterProblemList: {},
        medicalRecordsSize: 0,
    },

    modelReducer: (state, type, data, action, json) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case FETCH_MEDICAL_RECORD:
            case CREATE_MEDICAL_RECORD:
            case UPDATE_MEDICAL_RECORD:
                state = {
                    ...state,
                    medicalRecords: {
                        ...state.medicalRecords,
                        [data.id]: data,
                    }
                }
                break;
            case LIST_MEDICAL_RECORDS:
                state = {
                    ...state,
                    medicalRecordsSize: json.response.meta?.pagination?.record_count,
                    medicalRecords: _.keyBy(data, 'id')
                }
                break;
            case FETCH_MASTER_PROBLEM_LIST:
                state = {
                    ...state,
                    masterProblemList: {
                        ...state.masterProblemList,
                        [action.params.petId]: data,
                    }
                }
                break;
            default:
                break;
        }

        return state;
    }
}

export default MedicalRecord;