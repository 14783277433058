import {del, get, post, put} from "../../utils/fetch";
import {RequestState} from "../../constants/constants";
import _ from "lodash";
import {selectPetUserEmrLinks} from "./PetUserEmrLink";
import {selectMedicalRecords} from "./MedicalRecord";

const LIST_APPOINTMENTS = 'LIST_APPOINTMENTS';
const FETCH_APPOINTMENT = 'FETCH_APPOINTMENT';
const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
const GET_APPOINTMENTS_FROM_EMR = 'GET_APPOINTMENTS_FROM_EMR';

export const selectAppointments = (state) => Object.values(state.models.appointments);
export const selectAppointmentsSize = (state) => state.models.appointmentsSize;
export const selectAppointment = (state) => state.models.appointment;
export const selectPetAppointments = (state, petId) => {
    const petUserEmrLink = _.find(selectPetUserEmrLinks(state), emrLink => emrLink?.petId?.toString() === petId?.toString());

    return _.filter(selectAppointments(state), appt => appt.petUserEmrLinkId === petUserEmrLink?.id);
}

const Appointment = {
    actions: {
        listAppointments: (params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/appointments",
                params: {
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: LIST_APPOINTMENTS,
            }).then(response => response)
        },

        fetchAppointment: (appointmentId, params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/appointments/:appointmentId",
                params: {
                    appointmentId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_APPOINTMENT,
            }).then(response => response)
        },

        updateAppointment: (appointmentId, body, params = {}) => async (dispatch, getState) => {
            return put({
                url: "/api/appointments/:appointmentId",
                params: {
                    appointmentId,
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: UPDATE_APPOINTMENT,
            }).then(response => response)
        },

        createAppointment: (body, params = {}) => async (dispatch, getState) => {
            return post({
                url: "/api/appointments",
                params: {
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: CREATE_APPOINTMENT,
            }).then(response => response)
        },

        deleteAppointment: (appointmentId, params = {}) => async (dispatch, getState) => {
            return del({
                url: "/api/appointments/:appointmentId",
                params: {
                    appointmentId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: DELETE_APPOINTMENT,
            }).then(response => response)
        },

        getAppointmentsFromEmr: (petId) => async (dispatch, getState) => {
            return get({
                // url: "/api//appointments/sync-appointments-from-emrs/:petId",
                url: "/api//appointments/sync-appointments-from-emrs",
                params: {
                    // petId,
                },
                dispatch,
                withAuthHeaders: true,
                action: GET_APPOINTMENTS_FROM_EMR,
            }).then(response => response)
        },

        syncAppointments: (petId) => async (dispatch, getState) => {
            // const existingAppointments = await dispatch(Appointment.actions.listAppointments())
            // const appointmentsFromEmr = await dispatch(Appointment.actions.getAppointmentsFromEmr(petId))

        }
    },

    spec: {
        appointments: {},
        appointmentsSize: 0,
    },

    modelReducer: (state, type, data, action, json) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case FETCH_APPOINTMENT:
            case CREATE_APPOINTMENT:
            case UPDATE_APPOINTMENT:
                state = {
                    ...state,
                    appointments: {
                        ...state.appointments,
                        [data.id]: data,
                    }
                }
                break;
            case LIST_APPOINTMENTS:
                state = {
                    ...state,
                    appointmentsSize: json.response.meta?.pagination?.record_count,
                    appointments: _.keyBy(data, 'id')
                }
                break;
            default: break;
        }

        return state;
    }
}

export default Appointment;