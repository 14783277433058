import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {SignupPage} from "./pages/auth/SignupPage";
import {SigninPage} from "./pages/auth/SigninPage"
import './style/_app.scss';
import PrivateRoute, {AdminRoutes} from './components/privateRoute'
import {PageContainer} from './components/layout/PageContainer'

import {Urls} from "./constants/constants";
import {useEffect, useState} from "react";
import AppState from "./store/AppState";
import {useDispatch} from "react-redux";
import {MyPetsPage} from "./pages/pets/MyPetsPage";
import {EditPetPage} from "./pages/pets/EditPetPage";
import {MyAppointmentsPage} from "./pages/appointments/MyAppointmentsPage";
import {NotFoundPage} from "./pages/NotFoundPage";
import {MyMedicalRecordsPage} from "./pages/medical-records/MyMedicalRecordsPage";
import {MedicalRecordDetailsPage} from "./pages/medical-records/MedicalRecordDetailsPage";
import {PetMasterProblemListPage} from "./pages/medical-records/PetMasterProblemListPage";
import {PetDetails} from "./pages/pets/PetDetails";
import {AccountsListPage} from "./pages/veterinaries/AccountsListPage";
import {UserForm} from "./components/UserForm";
import {CreateUserPage} from "./pages/veterinaries/CreateUserPage";
import {EmrsListPage} from "./pages/emrs/EmrsListPage";
import {EditEmrPage} from "./pages/emrs/EditEmrPage";
import {EditUserPage} from "./pages/veterinaries/EditUserPage";

const App = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await dispatch(AppState.actions.initApp());
            setLoading(false);
        })();
    }, []);

    if (loading)
        return (<div className={"loading"}>Loading..</div>)

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path={Urls.SIGNIN} element={<SigninPage />} />
                    <Route path={Urls.SIGNUP} element={<SignupPage />} />
                    <Route exact element={<AdminRoutes />}>
                        <Route path={Urls.ACCOUNTS} element={<PageContainer page={AccountsListPage} />}/>
                        <Route path={Urls.CREATE_ACCOUNT} element={<PageContainer page={CreateUserPage} />}/>
                        <Route path={Urls.EDIT_ACCOUNT} element={<PageContainer page={EditUserPage} />}/>
                        <Route path={Urls.EMRS} element={<PageContainer page={EmrsListPage} />}/>
                        <Route path={Urls.ADD_EMR} element={<PageContainer page={EditEmrPage} />}/>
                        <Route path={Urls.EDIT_EMR} element={<PageContainer page={EditEmrPage} />}/>
                    </Route>
                    <Route exact element={<PrivateRoute/>}>
                        <Route path={Urls.HOME} element={<PageContainer page={MyPetsPage} />}/>
                        <Route path={Urls.PET_DETAILS} element={<PageContainer page={PetDetails} />}/>
                        <Route path={Urls.ADD_PET} element={<PageContainer page={EditPetPage} />}/>
                        <Route path={Urls.EDIT_PET} element={<PageContainer page={EditPetPage} />}/>
                        <Route path={Urls.APPOINTMENTS} element={<PageContainer page={MyAppointmentsPage} />}/>
                        <Route path={Urls.MEDICAL_RECORDS} element={<PageContainer page={MyMedicalRecordsPage} />}/>
                        <Route path={Urls.MEDICAL_RECORD_DETAILS} element={<PageContainer page={MedicalRecordDetailsPage} />}/>
                        <Route path={Urls.MASTER_PROBLEM_LIST_DETAILS} element={<PageContainer page={PetMasterProblemListPage} />}/>
                        <Route path="*" element={<PageContainer page={NotFoundPage}/>}/>
                    </Route>

                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;