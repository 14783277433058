import { combineReducers } from 'redux';
import ModelState from './ModelState';



import modelTypes from './models';
import AppState from "./AppState";

ModelState.init(modelTypes);

const reducer = combineReducers({
  models: ModelState.reducer,
  app: AppState.reducer,
});
export { reducer as default };
