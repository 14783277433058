import React, {useEffect, useState} from "react";
import Emr from "../../store/models/Emr";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {
    Button,
    Container,
    Group,
    Modal,
    Paper, Stack,
    Text,
    TextInput,
    Title
} from "@mantine/core";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "@mantine/form";
import {Urls} from "../../constants/constants";
import _ from "lodash";
import {BackButton} from "../../components/BackButton";
import {selectCurrentUserId} from "../../store/AppState";

const selector = createSelector(
    (state) => selectCurrentUserId(state),
    (userId) => ({
        userId
    })
)

export const EditEmrPage = ({...props}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams()
    const currentEmrId = params.emrId
    const isEditing = !_.isNil(currentEmrId)

    const {userId} = useSelector(state => selector(state))
    const [errorMessage, setErrorMessage] = useState(null)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const form = useForm({
        initialValues: {
            "name": '',
            "type": 'Vetspire',
            "api_token": '',
        },

        validate: {
            name: (value) => (!value ? '' : null),
            type: (value) => (!value ? 'EMR type' : null),
            api_token: (value) => (!value ? 'API Token' : null),
        },
    });

    useEffect(() => {
        (async () => {

            if (isEditing) {
                const emrResponse = await dispatch(Emr.actions.fetchEmr(currentEmrId))
                form.setValues(emrResponse)
            }
        })()
    }, []);

    const onSubmit = async (data) => {
        setErrorMessage(null)


        try {
            if (isEditing) {
                await dispatch(Emr.actions.updateEmr(currentEmrId, data));
            } else {
                await dispatch(Emr.actions.createEmr(data));
            }
            navigate(Urls.EMRS);
        } catch (e) {
            const message = e?.message?.toLowerCase()
            setErrorMessage(e?.message)
        }
    };

    const onDelete = () => {
        setShowConfirmDelete(true)
    }

    const onConfirmDelete = async () => {
        try {
            await dispatch(Emr.actions.deleteEmr(currentEmrId));
            navigate(Urls.EMRS);
        } catch (e) {
            setErrorMessage(e.message)
        }
    }

    const onCloseModal = () => {
        setShowConfirmDelete(false)
    }

    return (<Container className={"g-create-emr-page"} size={420}>
        <BackButton/>
        <Title align={"center"} mb={"md"}>
            {isEditing ? "edit emr" : "create new emr"}
        </Title>

        <form onSubmit={form.onSubmit(onSubmit)}>
            <Paper shadow={"md"} p={"xl"} radius={"lg"}>
                {errorMessage && <Text c={"red"} size={"sm"} mb={"sm"}>{errorMessage}</Text>}
                <TextInput
                    label="Name"
                    mb={"sm"}
                    required
                    {...form.getInputProps('name')}/>
                <TextInput
                    label="Type"
                    mb={"sm"}
                    required
                    {...form.getInputProps('type')}/>
                <TextInput
                    label="API token"
                    mb={"sm"}
                    required
                    {...form.getInputProps('api_token')}/>

                <Group mt="xl" justify={isEditing ? "space-between" : "center"}>
                    <Button size={"lg"} type={"submit"} radius={"lg"}>
                        {isEditing ? "update" : "create"}
                    </Button>
                    {isEditing && <Button color={"gray"}
                                          size={"lg"} radius={"lg"}
                                          onClick={onDelete}>
                        delete
                    </Button>}
                </Group>
            </Paper>
        </form>

        <Modal opened={showConfirmDelete} withCloseButton={false} centered
               onClose={onCloseModal} radius="md">
            <Stack align={"center"} mt={"xl"} mb={"lg"}>
                <Text size={"lg"} mb={"lg"}>Are you sure you want to delete this EMR?</Text>

                <Group>
                    <Button  size="lg" color="gray"
                             radius={"lg"} mr={"xl"} onClick={onCloseModal}>Cancel</Button>
                    <Button size="lg" color="red"
                            radius={"lg"} onClick={onConfirmDelete}>Confirm</Button>
                </Group>
            </Stack>
        </Modal>
    </Container>)
}