import {del, get, post, put,} from "../../utils/fetch";
import {RequestState} from "../../constants/constants";
import {selectCurrentUser, selectCurrentUserId} from "../AppState";
import _ from "lodash";


// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
export const CHECK_EMAIL_FOR_EMR_LINKS = 'CHECK_EMAIL_FOR_EMR_LINKS';
export const LIST_USER_EMR_LINKS = 'LIST_USER_EMR_LINKS';
export const FETCH_USER_EMR_LINK = 'FETCH_USER_EMR_LINK';
export const CREATE_USER_EMR_LINK = 'CREATE_USER_EMR_LINK';
export const UPDATE_USER_EMR_LINK = 'UPDATE_USER_EMR_LINK';
export const DELETE_USER_EMR_LINK = 'DELETE_USER_EMR_LINK';
export const SYNC = 'SYNC';

// ===========================
// SELECTORS
// ===========================
export const selectUserEmrLinks = state => Object.values(state.models.userEmrLinks);
export const selectUserEmrLink = (state, userEmrLinkId) => state.models.userEmrLinks[userEmrLinkId];

// ===========================
// MODEL
// ===========================
const UserEmrLink = {
    actions: {

        listLinks: (params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/user-emr-links",
                params: {
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: LIST_USER_EMR_LINKS,
            }).then(response => response.rows)
        },

        fetchLink: (id, params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/user-emr-links/:id",
                params: {
                    id,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_USER_EMR_LINK,
            }).then(response => response)
        },

        updateLink: (id, body, params = {}) => async (dispatch, getState) => {
            return put({
                url: "/api/user-emr-links/:id",
                params: {
                    id,
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: UPDATE_USER_EMR_LINK,
            }).then(response => response)
        },

        createLink: (body, params = {}) => async (dispatch, getState) => {
            return post({
                url: "/api/user-emr-links",
                params: {
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: CREATE_USER_EMR_LINK,
            }).then(response => response)
        },

        deleteLink: (id, params = {}) => async (dispatch, getState) => {
            return del({
                url: "/api/user-emr-links/:id",
                params: {
                    id,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: DELETE_USER_EMR_LINK,
            }).then(response => response)
        },


        checkEmail: () => async (dispatch, getState) => {
            const user = selectCurrentUser(getState());

            return post({
                url: "/api/user-emr-links/check-email",
                body: {email: user.email},
                dispatch,
                withAuthHeaders: true,
                action: CHECK_EMAIL_FOR_EMR_LINKS,
            })
        },

        syncUserEmrs: () => async (dispatch, getState) => {
            const userId = selectCurrentUserId(getState());
            const checkEmailResponse = await dispatch(UserEmrLink.actions.checkEmail());

            if (_.isEmpty(checkEmailResponse)) return;

            const existingEmrLinks = await dispatch(UserEmrLink.actions.listLinks());
            await Promise.all(
                checkEmailResponse.map(async (userEmrLink) => {
                    const {emrId, clientId} = userEmrLink;
                    await dispatch(UserEmrLink.actions.sync(emrId))

                    // const body = {emrId, clientId, userId};
                    // const isNewLink = !_.find(existingEmrLinks, existingEmrLinks =>
                    //     existingEmrLinks.clientId?.toString() === clientId.toString())
                    //
                    // if (isNewLink) {
                    //     await dispatch(UserEmrLink.actions.createLink(body));
                    // }
                })
            )
        },

        sync: (emrId) => async (dispatch, getState) => {
            const userId = selectCurrentUserId(getState());

            return get({
                url: "/api/sync",
                params: {emrId},
                dispatch,
                withAuthHeaders: true,
                action: SYNC,
            })
        }

    },

    spec: {
        userEmrLinks: {},
    },

    modelReducer: (state, type, data, action, json) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case FETCH_USER_EMR_LINK:
            case CREATE_USER_EMR_LINK:
            case UPDATE_USER_EMR_LINK:
                state = {
                    ...state,
                    userEmrLinks: {
                        ...state.userEmrLinks,
                        [data.id]: data,
                    }
                }
                break;
            case LIST_USER_EMR_LINKS:
                state = {
                    ...state,
                    userEmrLinks: _.keyBy(data, 'id')
                }
                break;
            default: break;
        }

        return state;
    }
}
export default UserEmrLink;
