import * as React from 'react';
import {useState} from "react";
import {useDispatch} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import User from "../../store/models/User";
import {Urls} from "../../constants/constants";
import {Anchor, Box, Button, Container, Paper, PasswordInput, Text, TextInput, Title,} from '@mantine/core';
import {useForm} from "@mantine/form";


export const SigninPage = ({}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(null)

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
        },

        validate: {
            // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    const onSubmit = async (data) => {
        const {email, password} = data
        setErrorMessage(null)

        try {
            await dispatch(User.actions.login(email, password));
            navigate(Urls.HOME);
        } catch (e) {
            const message = e.message.toLowerCase()
            if (message.includes("password")) {
                form.setErrors({password: e.message})
            } else if (message.includes("email")) {
                form.setErrors({email: e.message})
            } else {
                setErrorMessage(e.message)
            }
        }
    };

    return (
        <Container className={"g-signin"} size={420} my={40}>
            <Title ta="center">
                Sign in
            </Title>

            <Paper className={"signin-wrapper"} withBorder shadow="md" p={30} mt={30} radius="md">
                <form onSubmit={form.onSubmit(onSubmit)}>

                    {errorMessage && <Text c={"red"} size={"sm"} mb={"sm"}>{errorMessage}</Text>}
                    <TextInput
                        label="Email"
                        placeholder="your@email.com"
                        required
                        {...form.getInputProps('email')}/>
                    <PasswordInput
                        label="Password"
                        placeholder="Your password"
                        required
                        mt="md"
                        {...form.getInputProps('password')}/>
                    <Button fullWidth mt="xl" type={"submit"} radius={"md"}>
                        Sign in
                    </Button>
                    <Box className={"need-account"} mt={"md"}>
                        <Anchor to={Urls.SIGNUP} component={Link} size="sm">
                            Don't have an account? Sign up
                        </Anchor>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
}