import {Container, Title} from "@mantine/core";
import {UserForm} from "../../components/UserForm";
import {CreateUserType, SignupTypes} from "../../constants/constants";

export const CreateUserPage = ({}) => {
    return (
        <Container className={"g-create-account"} size={420} my={40}>
            <Title ta="center">
                Create a new account
            </Title>

            <UserForm createUserType={CreateUserType.CREATE} />
        </Container>);
}