import User from "./models/User";
import Pet from "./models/Pet";
import UserEmrLink from "./models/UserEmrLink";
import PetUserEmrLink from "./models/PetUserEmrLink";
import Appointment from "./models/Appointment";
import MedicalRecord from "./models/MedicalRecord";
import Emr from "./models/Emr";

const modelTypes = {
    User,
    Pet,
    UserEmrLink,
    PetUserEmrLink,
    Appointment,
    MedicalRecord,
    Emr
}

export default modelTypes;
