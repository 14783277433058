import {Page, View, Text, Document, StyleSheet, Image, Font} from "@react-pdf/renderer";
import {getYearsMonths} from "../../utils/date";
import dayjs from "dayjs";

//TODO better pdf styling

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#F4F4F4',
        padding: 20,
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
    },
    logoContainer: {
        textAlign: 'center',
        marginBottom: 20,
    },
    logo: {
        width: 80, // Adjust the width as needed
        margin: '0 auto',
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 20,
        padding: '10px 20px',
        color: '#ffffff',
        backgroundColor: '#f06327',
        borderRadius: 8,
    },
    infoItem: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 8,
        marginLeft: 20,
    },
    label: {
        fontWeight: 'bold',
        marginRight: '6px',
    },
});

export const PetPdf = ({pet}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <View style={styles.logoContainer}>
                    <Image src="/logo.png" alt="My Paws Logo" style={styles.logo} />
                    <Text style={styles.title}>my paws</Text>
                </View>

                <Text style={styles.sectionTitle}>{pet.name}'s Information</Text>

                <View style={styles.infoItem}>
                    <Text style={styles.label}>Breed:</Text>
                    <Text>{pet.breed}</Text>
                </View>
                <View style={styles.infoItem}>
                    <Text style={styles.label}>Birthdate:</Text>
                    <Text>{dayjs(pet.birthdate).format("MM/DD/YYYY")}</Text>
                </View>
                <View style={styles.infoItem}>
                    <Text style={styles.label}>Color:</Text>
                    <Text>{pet.color}</Text>
                </View>
                <View style={styles.infoItem}>
                    <Text style={styles.label}>Weight:</Text>
                    <Text>{pet.weight} lbs</Text>
                </View>
            </View>
        </Page>
    </Document>
);