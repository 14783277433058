import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import User from "../store/models/User";
import {CreateUserType, Urls, UserRoles} from "../constants/constants";
import {Button, NativeSelect, Paper, PasswordInput, Text, TextInput,} from '@mantine/core';
import {useForm} from "@mantine/form";
import {SignupTypes} from "../constants/constants";
import _ from "lodash";
import {notifications} from "@mantine/notifications";

export const UserForm = ({user, createUserType}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null)

    const form = useForm({
        initialValues: {
            email: user?.email || '',
            password: '',
            confirmPassword: '',
            role: user?.role || UserRoles.USER
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            password: (value, values) => {
                if (createUserType === CreateUserType.EDIT) return null
                if (value.length < 7) return 'Password too short'
                if (value !== values.confirmPassword) return 'Passwords don\'t match'
                return null
            },
            confirmPassword: (value, values) => (value === values.password ? null : 'Passwords don\'t match')
        },
    });


    const onSubmit = async (data) => {
        const {email, password, confirmPassword, role} = data

        setErrorMessage(null)

        try {
            if (createUserType === CreateUserType.CREATE) {
                await dispatch(User.actions.createUser(data));
                notifications.show({title: 'User created', message: `User ${email} created`})
            } else if (createUserType === CreateUserType.EDIT) {
                await dispatch(User.actions.updateUser(user.id, data));
                notifications.show({title: 'User updated', message: `User ${email} updated`})
            } else {
                await dispatch(User.actions.signup(email, password, confirmPassword));
            }

            if (createUserType === CreateUserType.SIGNUP) {
                navigate(Urls.HOME);
            } else {
                navigate(-1);
            }
        } catch (error) {
            setErrorMessage(error.message)
        }
    };

    return (
        <Paper className={"signup-wrapper"} withBorder shadow="md" p={30} mt={30} radius="md">
            <form onSubmit={form.onSubmit(onSubmit)}>

                {errorMessage && <Text c={"red"} size={"sm"} mb={"sm"}>{errorMessage}</Text>}
                <TextInput
                    label="Email"
                    placeholder="enter an email here"
                    required
                    {...form.getInputProps('email')}/>
                {createUserType !== CreateUserType.EDIT && <PasswordInput
                    label="Password"
                    placeholder="enter a password"
                    required
                    mt="md"
                    {...form.getInputProps('password')}/>}
                {createUserType !== CreateUserType.EDIT && <PasswordInput
                    label="Confirm password"
                    placeholder="enter the same password"
                    required
                    mt="md"
                    {...form.getInputProps('confirmPassword')}/>}

                {createUserType !== CreateUserType.SIGNUP && <NativeSelect
                    label="Role"
                    placeholder="Select role"
                    data={_.values(UserRoles)}
                    required
                    mt="md"
                    {...form.getInputProps('role')} />}

                <Button fullWidth mt="xl" type={"submit"} radius={"md"}>
                    {createUserType === CreateUserType.SIGNUP ? "Sign up" : "Save"}
                </Button>
            </form>
        </Paper>);
}