import _ from "lodash";
import {ActionIcon, Group, rem, Table, Title} from "@mantine/core";
import {IconEdit, IconTrash} from "@tabler/icons-react";
import React from "react";
import {notifications, useNotifications} from "@mantine/notifications";

export const EmrsList = ({children, emrs, onEdit, onDelete, title = "Clinics List"}) => {
    return (
        <div className={"g-emrs-list"}>
            <Title mb={"lg"}>{title}</Title>

            <Table striped stickyHeader mb={"xl"} verticalSpacing="sm">
                <Table.Thead c={"white"} style={{backgroundColor: "var(--mantine-color-mp-orange-5)"}}>
                    <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Type</Table.Th>
                        <Table.Th>API token</Table.Th>
                        <Table.Th ta={"right"} pr={"xl"}>Actions</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {_.isEmpty(emrs) ? <Table.Tr>
                            <Table.Td colSpan={6} ta={"center"}>No clinics found.</Table.Td>
                        </Table.Tr>
                        : _.map(emrs, (emr, index) => {
                            return <EmrRow key={index}
                                           emr={emr}
                                           onEdit={onEdit}
                                           onDelete={onDelete}/>
                        })}
                </Table.Tbody>
            </Table>
            {children}
        </div>
    )
}

const EmrRow = ({emr, onEdit, onDelete}) => {

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        notifications.show({
            title: 'API token copied to clipboard',
        })
    }

    return (
        <Table.Tr>
            <Table.Td>{emr.name ?? '-'}</Table.Td>
            <Table.Td>{emr.type}</Table.Td>
            <Table.Td style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(emr.api_token)}>{emr.api_token}</Table.Td>
            <Table.Td>
                <Group justify={"right"} gap={"sm"}>
                    <ActionIcon className={"edit-btn"} radius={"md"}
                                aria-label="Edit" onClick={() => onEdit(emr)}>
                        <IconEdit style={{width: '70%', height: '70%'}} stroke={1.5}/>
                    </ActionIcon>
                    {/*<ActionIcon className={"delete-btn"} radius={"md"} mr={"sm"}*/}
                    {/*            aria-label="Delete" onClick={() => onDelete(emr)}>*/}
                    {/*    <IconTrash style={{width: '70%', height: '70%'}} stroke={1.5}/>*/}
                    {/*</ActionIcon>*/}
                </Group>
            </Table.Td>
        </Table.Tr>
    )
}