import {del, get, post, put} from "../../utils/fetch";
import {RequestState} from "../../constants/constants";
import _ from "lodash";

const LIST_EMRS = 'LIST_EMRS';
const FETCH_EMR = 'FETCH_EMR';
const UPDATE_EMR = 'UPDATE_EMR';
const CREATE_EMR = 'CREATE_EMR';
const DELETE_EMR = 'DELETE_EMR';

export const selectEmrs = (state) => _.values(state.models.emrs);
export const selectEmr = (state) => state.models.emr;


const Emr = {
    actions: {
        listEmrs: (params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/emrs",
                params: {
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: LIST_EMRS,
            }).then(response => response)
        },

        fetchEmr: (emrId, params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/emrs/:emrId",
                params: {
                    emrId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_EMR,
            }).then(response => response)
        },

        updateEmr: (emrId, body, params = {}) => async (dispatch, getState) => {
            return put({
                url: "/api/emrs/:emrId",
                params: {
                    emrId,
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: UPDATE_EMR,
            }).then(response => response)
        },

        createEmr: (body, params = {}) => async (dispatch, getState) => {
            return post({
                url: "/api/emrs",
                params: {
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: CREATE_EMR,
            }).then(response => response)
        },

        deleteEmr: (emrId, params = {}) => async (dispatch, getState) => {
            return del({
                url: "/api/emrs/:emrId",
                params: {
                    emrId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: DELETE_EMR,
            }).then(response => response)
        },
    },

    spec: {
        emrs: {},
    },

    modelReducer: (state, type, data, action, json) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case FETCH_EMR:
            case CREATE_EMR:
            case UPDATE_EMR:
                state = {
                    ...state,
                    emrs: {
                        ...state.emrs,
                        [data.id]: data,
                    }
                }
                break;
            case LIST_EMRS:
                state = {
                    ...state,
                    emrs: _.keyBy(data, 'id')
                }
                break;
            default: break;
        }

        return state;
    }
}

export default Emr;