import {RequestState} from "../constants/constants";
import User, {selectUser} from "./models/User";
import MPStorage from "../utils/storage";

// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
export const INIT_APP = 'INIT_APP';
export const SEARCH_CHANGE = 'SEARCH_CHANGE';

// ===========================
// SELECTORS
// ===========================

export const selectAppStarted = state => state.app.appStarted;
export const selectCurrentUserId = state => state.app.userId;
export const selectCurrentUser = state => selectUser(state, selectCurrentUserId(state));
export const selectSearchText = state => state.app.searchText;

// ===========================
// MODEL
// ===========================
const AppState = {
    actions: {

        initApp: (fetchUser = true) => async (dispatch, getState) => {
            const userId = MPStorage.getUserId();

            if (userId) {
                try {
                    await dispatch(User.actions.fetchCurrentUser());
                } catch (e) {
                    console.log(e.message)
                }
            }

            dispatch({
                type: INIT_APP,
                userId,
            });
        },

        onSearchChange: (searchText) => async (dispatch, getState) => {
            dispatch({
                type: 'SEARCH_CHANGE',
                searchText,
            });
        }
    },

    spec: {
        appStarted: false,
        userId: null,
        searchText: '',
    },

    reducer
        (state = AppState.spec, action) {
        const {type} = action;

        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case INIT_APP:
                return {
                    ...state,
                    appStarted: true,
                    userId: action.userId,
                };
            case SEARCH_CHANGE:
                return {
                    ...state,
                    searchText: action.searchText,
                };
            default: return state;
        }
    }
}
export default AppState;
