import {del, get, post, put,} from "../../utils/fetch";
import {ApiUrls, RequestState, SignupTypes, UserRoles} from "../../constants/constants";
import {mergeApiUrl} from "../../utils/url";
import MPStorage from "../../utils/storage";
import AppState, {selectCurrentUserId} from "../AppState";
import dayjs from "dayjs";
import _ from "lodash";


// ===========================
// HELPERS
// ===========================

export const isUserAdmin = (user) => user?.role === UserRoles.ADMIN;

// ===========================
// ACTIONS
// ===========================
export const SIGNUP = 'SIGNUP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER = 'FETCH_USER';
export const LIST_USERS = 'LIST_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';

// ===========================
// SELECTORS
// ===========================
export const selectUser = (state, id) => state.models.users[id];
export const selectAccounts = (state) => _.values(state.models.accounts);
export const selectAccountsSize = (state) => _.values(state.models.accountsSize);

// ===========================
// MODEL
// ===========================
const User = {
    actions: {

        fetchCurrentUser: (params = {}) => async (dispatch, getState) => {
            return get({
                url: mergeApiUrl(ApiUrls.BASE_AUTH, '/current-user'),
                params: {
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_USER,
            }).then(response => response)
        },

        fetchUser: (userId, params = {}) => async (dispatch, getState) => {
            return get({
                url: mergeApiUrl(ApiUrls.BASE_USER, '/:userId'),
                params: {
                    userId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_USER,
            }).then(response => response)
        },

        signup: (email, password, confirmPassword) => async (dispatch, getState) => {
            const apiResponse = await post({
                url: mergeApiUrl(ApiUrls.BASE_AUTH, '/register'),
                body: {
                    email,
                    password,
                    confirm_password: confirmPassword,
                },
                dispatch,
                action: SIGNUP,
                withAuthHeaders: true,
            });

            await dispatch(User.actions.authenticateUser(apiResponse.user.id, apiResponse.tokens));
            return true;
        },

        login: (email, password) => async (dispatch, getState) => {
            const apiResponse = await post({
                url: mergeApiUrl(ApiUrls.BASE_AUTH, '/login'),
                action: LOGIN,
                dispatch,
                body: {
                    email: email,
                    password: password,
                },
            });

            await dispatch(User.actions.authenticateUser(apiResponse.user.id, apiResponse.tokens))

            return selectCurrentUserId(getState());
        },

        authenticateUser: (userId, tokens) => async (dispatch, getState) => {
            MPStorage.saveAuth(userId, tokens);
            await dispatch(AppState.actions.initApp(false))
        },

        refreshToken: () => async (dispatch, getState) => {
            const refreshToken = MPStorage.getTokens()?.refresh

            if (dayjs().isAfter(dayjs(refreshToken.expires))) {
                dispatch(User.actions.logout())
                return false
            }

            try {
                const response = await post({
                    url: mergeApiUrl(ApiUrls.BASE_AUTH, '/refresh-token'),
                    action: LOGIN,
                    dispatch,
                    body: {
                        refresh_token: refreshToken.token,
                    },
                });

                MPStorage.saveTokens(response.tokens)
                return true
            } catch (e) {
                dispatch(User.actions.logout())
                console.log(e.message)
            }

            return false
        },


        logout: () => async (dispatch) => {
            MPStorage.clearItem(MPStorage.USER_ID)
            MPStorage.clearItem(MPStorage.USER_TOKENS)
            window.location.reload()
        },

        //add user CRUD endpoints
        listUsers: (params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/users",
                params: {
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: LIST_USERS,
            }).then(response => response)
        },

        updateUser: (userId, body, params = {}) => async (dispatch, getState) => {
            return put({
                url: "/api/users/:userId",
                params: {
                    userId,
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: UPDATE_USER,
            }).then(response => response)
        },

        createUser: (body, params = {}) => async (dispatch, getState) => {
            return post({
                url: "/api/users",
                params: {
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: CREATE_USER,
            }).then(response => response)
        },

        deleteUser: (userId, params = {}) => async (dispatch, getState) => {
            return del({
                url: "/api/users/:userId",
                params: {
                    userId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: DELETE_USER,
            }).then(response => response)
        },


    },

    spec: {
        users: {},
        accounts: {},
        accountsSize: {},
    },

    modelReducer: (state, type, data, action, json) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case FETCH_USER:
            case SIGNUP:
            case LOGIN:
            // case UPDATE_USER:
            case CREATE_USER:
                state = {
                    ...state,
                    users: {
                        ...state.users,
                        [data.id]: data,
                    }
                }
                break;

            case LIST_USERS:
                state = {
                    ...state,
                    accounts: _.keyBy(data, 'id')
                }
                break;

            // case DELETE_USER:
            //     state = {
            //         ...state,
            //         users: _.omit(state.users, [data.id])
            //     }
            //     break;

            default:
                break;
        }

        return state;
    }
}
export default User;
