import {useState} from 'react';
import {
    IconBuildingCommunity, IconBuildingHospital,
    IconCalendarEvent, IconCirclesRelation,
    IconDashboard,
    IconFiles,
    IconHealthRecognition,
    IconLogout,
    IconMicroscope, IconUsers,
} from '@tabler/icons-react';
import User, {isUserAdmin} from "../../store/models/User";
import {useDispatch, useSelector} from "react-redux";
import {Urls} from "../../constants/constants";
import {useNavigate} from "react-router-dom";
import {createSelector} from "reselect";
import {selectCurrentUser} from "../../store/AppState";

const data = [
    {link: Urls.HOME, label: 'pets', icon: IconDashboard},
    {link: Urls.APPOINTMENTS, label: 'appointments', icon: IconCalendarEvent},
    {link: Urls.MEDICAL_RECORDS, label: 'medical records', icon: IconFiles},
    {link: Urls.LAB_RESULTS, label: 'lab results', icon: IconMicroscope},
    {link: Urls.INSURANCE, label: 'insurance', icon: IconHealthRecognition},
];

const adminData = [
    {link: Urls.ACCOUNTS, label: 'accounts', icon: IconUsers},
    {link: Urls.EMRS, label: 'clinics', icon: IconBuildingHospital},
]

const selector = createSelector(
    (state) => selectCurrentUser(state),
    (currentUser) => ({
            currentUser,
        }
    ))

export const Navbar = () => {
    const dispatch = useDispatch();
    const {currentUser} = useSelector(state => selector(state));
    const navigate = useNavigate();
    const [active, setActive] = useState('my pets');
    const path = window.location.pathname;
    const isAdmin = isUserAdmin(currentUser);

    const links = data.map((item) => {
        return (
            <a
                className={"link"}
                data-active={item.link === path || undefined}
                href={item.link}
                key={item.label}
                onClick={(event) => {
                    event.preventDefault();
                    setActive(item.label);
                    navigate(item.link)
                }}
            >
                <item.icon className={"linkIcon"} stroke={1.5}/>
                <span>{item.label}</span>
            </a>
        )
    });

    const adminLinks = adminData.map((item) => {
        return (
            <a
                className={"link"}
                data-active={item.link === path || undefined}
                href={item.link}
                key={item.label}
                onClick={(event) => {
                    event.preventDefault();
                    setActive(item.label);
                    navigate(item.link)
                }}
            >
                <item.icon className={"linkIcon"} stroke={1.5}/>
                <span>{item.label}</span>
            </a>)});


    return (
        <div className={"g-navbar"}>
            <div className={"navbarMain"}>
                {links}
            </div>
            {isAdmin && <div className={"navbarMain"}>
                {adminLinks}
            </div>}

        </div>
    );
}