import React from 'react';
import {CreateUserType, SignupTypes} from "../../constants/constants";
import {Container, Title,} from '@mantine/core';
import {UserForm} from "../../components/UserForm";

export const SignupPage = ({}) => {

    return (
        <Container className={"g-signup"} size={420} my={40}>
            <Title ta="center">
                Create a new account
            </Title>

            <UserForm createUserType={CreateUserType.SIGNUP}/>
        </Container>);
}