import _ from "lodash";
import {ActionIcon, Badge, Group, rem, Table, Title} from "@mantine/core";
import {IconEdit, IconFile, IconFolderOpen, IconTrash} from "@tabler/icons-react";
import React from "react";

export const UsersList = ({children, users, onEdit, onDelete, title = "users list"}) => {
    return (
        <div className={"g-users-list"}>

            <Table striped stickyHeader mb={"xl"} verticalSpacing="sm">
                <Table.Thead c={"white"} style={{backgroundColor: "var(--mantine-color-mp-orange-5)"}}>
                    <Table.Tr>
                        <Table.Th>Email</Table.Th>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Role</Table.Th>
                        <Table.Th ta={"right"} pr={"xl"}>Actions</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {_.isEmpty(users) ? <Table.Tr>
                            <Table.Td colSpan={6} ta={"center"}>No users found.</Table.Td>
                        </Table.Tr>
                        : _.map(users, (user, index) => {
                            return <UserRow key={index}
                                            user={user}
                                            onEdit={onEdit}
                                            onDelete={onDelete}/>
                        })}
                </Table.Tbody>
            </Table>
            {children}
        </div>
    )
}

const UserRow = ({user, onEdit, onDelete}) => {
    return (
        <Table.Tr>
            <Table.Td>{user.email}</Table.Td>
            <Table.Td>{`${user.first_name ?? '-'} ${user.last_name ?? ''}`}</Table.Td>
            <Table.Td>{user.role}</Table.Td>
            <Table.Td>
                <Group justify={"right"} gap={"sm"}>
                    <ActionIcon className={"view-btn"} radius={"md"}
                                aria-label="View" onClick={() => onEdit(user)}>
                        <IconEdit style={{width: '70%', height: '70%'}} stroke={1.5}/>
                    </ActionIcon>
                    <ActionIcon className={"delete-btn"} radius={"md"} mr={"sm"}
                                aria-label="Delete" onClick={() => onDelete(user)}>
                        <IconTrash style={{width: '70%', height: '70%'}} stroke={1.5}/>
                    </ActionIcon>
                </Group>
            </Table.Td>
        </Table.Tr>
    )
}