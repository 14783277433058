import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Button, rem } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import Emr, { selectEmrs } from '../../store/models/Emr';
import { useNavigate } from 'react-router-dom';
import { EmrsList } from '../../components/emrs/EmrsList';
import {Urls} from "../../constants/constants";
import {mergeUrl} from "../../utils/url";

const selector = createSelector(
    (state) => selectEmrs(state),
    (emrs) => ({
        emrs,
    })
)

export const EmrsListPage = ({...props}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { emrs } = useSelector(state => selector(state))

    useEffect(() => {
        (async () => {
            try {
                await dispatch(Emr.actions.listEmrs())
            } catch (e) {
                console.log(e)
            }
        })()
    }, [dispatch]);

    const onCreateEmr = () => {
        navigate(Urls.ADD_EMR)
    }

    const onEditEmr = (emr) => {
        navigate(mergeUrl(Urls.EDIT_EMR, {emrId: emr.id}))
    }

    const onDeleteEmr = (id) => {
        console.log('delete', id)
    }

    return (
        <div className="g-my-emrs-page">
            <EmrsList
                title={"EMRs List"}
                emrs={emrs}
                onEdit={onEditEmr}
                onDelete={onDeleteEmr}>

                <Button w={rem(300)} leftSection={<IconPlus/>}
                        size="lg" radius={"sm"} color={"blue"}
                        mb={"lg"}
                        onClick={onCreateEmr}>Create new EMR</Button>

            </EmrsList>
        </div>
    );
};