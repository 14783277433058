import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Button, Group, Loader, NativeSelect, Pagination, rem, TextInput, Title} from "@mantine/core";
import {IconNewSection, IconPlus, IconSearch} from "@tabler/icons-react";
import User, {selectAccounts, selectAccountsSize, selectVeterinaries} from "../../store/models/User";
import {Urls, UserRoles} from "../../constants/constants";
import {useNavigate} from "react-router-dom";
import {UsersList} from "../../components/UsersList";
import {mergeUrl} from "../../utils/url";
import {modals} from "@mantine/modals";
import {notifications} from "@mantine/notifications";
import Emr, {selectEmrs} from "../../store/models/Emr";
import _ from "lodash";
import Pet from "../../store/models/Pet";


const selector = createSelector(
    (state) => selectAccounts(state),
    (state) => selectEmrs(state),
    (state) => selectAccountsSize(state),
    (accounts, emrs, accountsSize) => ({
        accounts, emrs, accountsSize
    })
)

export const AccountsListPage = ({...props}) => {
    const ACCOUNTS_PER_PAGE = 10;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fetchUsersLoading, setFetchUsersLoading] = useState(false);
    const {accounts, accountsSize, emrs} = useSelector(state => selector(state))
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedEmr, setSelectedEmr] = useState('');
    const [selectedRole, setSelectedRole] = useState('');

    useEffect(() => {
        dispatch(Emr.actions.listEmrs())
    }, []);

    useEffect(() => {
        fetchUsers()
    }, [currentPage, selectedEmr, selectedRole]);

    const fetchUsers = async () => {
        try {
            setFetchUsersLoading(true)
            const params = {}
            params.page = currentPage
            params.pageSize = ACCOUNTS_PER_PAGE
            if (!_.isEmpty(selectedRole)) {
                params.role = selectedRole
            }
            if (!_.isEmpty(selectedEmr)) {
                params.emrId = selectedEmr
            }
            await dispatch(User.actions.listUsers(params))
        } catch (e) {
            notifications.show({title: 'Error fetching pets'})
            console.log(e)
        }

        setFetchUsersLoading(false)
    }

    const onCreateAccount = () => {
        navigate(Urls.CREATE_ACCOUNT)
    }

    const onEditUser = (user) => {
        navigate(mergeUrl(Urls.EDIT_ACCOUNT, {id: user.id}))
    }

    const onDeleteUser = (user) => {
        modals.openConfirmModal({
            title: `Are you sure you want to delete ${user?.email} account?`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            onConfirm: async () => {
                await dispatch(User.actions.deleteUser(user.id))
                dispatch(User.actions.listUsers())
                notifications.show({title: 'Account deleted'})
            }
        });
    }

    const onEmrSelected = (event) => {
        setSelectedEmr(event.target.value);
    }
    const onRoleSelected = (event) => {
        setSelectedRole(event.target.value);
    }

    return (<div className="g-my-pets-page">
        {fetchUsersLoading && <Loader />}

        <Group mb={20} gap={"xl"} align={"center"}>
            <Title>Accounts</Title>

            <NativeSelect
                value={selectedRole}
                onChange={onRoleSelected}
                placeholder="Select role">
                <option value="">Select role</option>
                {_.map(UserRoles, (role, index) => (
                    <option key={index} value={role}>
                        {role}
                    </option>
                ))}
            </NativeSelect>

            <NativeSelect
                value={selectedEmr}
                onChange={onEmrSelected}
                placeholder="Select clinic">
                <option value="">Select clinic</option>
                {emrs.map((emr, index) => (
                    <option key={index} value={emr.id}>
                        {emr.name}
                    </option>
                ))}
            </NativeSelect>

        </Group>
        <UsersList
            users={accounts}
                   onEdit={onEditUser}
                   onDelete={onDeleteUser}>

            <Button w={rem(300)} leftSection={<IconPlus/>}
                    size="lg" radius={"sm"} color={"blue"}
                    mb={"lg"}
                    onClick={onCreateAccount}>create new account</Button>

        </UsersList>

        <Pagination
            mt={"lg"}
            initialPage={currentPage}
            total={Math.max(accountsSize / ACCOUNTS_PER_PAGE, 1)}
            perPage={ACCOUNTS_PER_PAGE}
            onChange={setCurrentPage} />

    </div>)
}