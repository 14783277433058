import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import Pet, {selectPet} from "../../store/models/Pet";
import {PetCard} from "../../components/pet/PetCard";
import {Button, Card, Loader, Pagination, Progress, Text, Title} from "@mantine/core";
import {MedicalRecordCardsList} from "../../components/medical-record/MedicalRecordCardsList";
import {Urls} from "../../constants/constants";
import {mergeUrl} from "../../utils/url";
import {modals} from "@mantine/modals";
import MedicalRecord, {
    selectMasterProblemList,
    selectMedicalRecordsSize,
    selectPetMedicalRecords
} from "../../store/models/MedicalRecord";
import {AppointmentCardsList} from "../../components/appointment/AppointmentCardsList";
import Appointment, {selectAppointmentsSize, selectPetAppointments} from "../../store/models/Appointment";
import {BackButton} from "../../components/BackButton";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import PetUserEmrLink from "../../store/models/PetUserEmrLink";

const selector = createSelector(
    (state, petId) => selectPet(state, petId),
    (state, petId) => selectPetMedicalRecords(state, petId),
    (state, petId) => selectPetAppointments(state, petId),
    (state, petId) => selectMasterProblemList(state, petId),
    (state, petId) => selectMedicalRecordsSize(state),
    (state, petId) => selectAppointmentsSize(state),
    (pet, medRecs, appointments, masterProblemList, medRecsSize, appointmentsSize) => ({
        pet,
        medRecs,
        appointments,
        masterProblemList,
        medRecsSize,
        appointmentsSize
    })
)

export const PetDetails = ({...props}) => {
    const MEC_RECS_PER_PAGE = 10;
    const APPTS_PER_PAGE = 10;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {petId} = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {pet, medRecs, appointments, masterProblemList, medRecsSize, appointmentsSize} = useSelector(state => selector(state, petId))
    const [medRecCurrentPage, setMedRecCurrentPage] = useState(0);
    const [appointmentsCurrentPage, setAppointmentsCurrentPage] = useState(0);

    console.log('medRecsSize / MEC_RECS_PER_PAGE')
    console.log(medRecsSize)
    console.log(MEC_RECS_PER_PAGE)

    useEffect(() => {
        loadData()
    }, []);

    useEffect(() => {
        fetchMedRecs()
    }, [medRecCurrentPage]);


    useEffect(() => {
        fetchAppointments()
    }, [appointmentsCurrentPage]);

    const loadData = async() => {
        setLoading(true)
        setError(null)
        try {
            await Promise.all([
                dispatch(Pet.actions.fetchPet(petId)),
                fetchMedRecs(),
                fetchAppointments(),
                dispatch(PetUserEmrLink.actions.listLinks())
            ])
        } catch (e) {
            setError(e.message)
            console.log(e)
        }
        setLoading(false)
    }

    const fetchMedRecs = async () => {
        return dispatch(MedicalRecord.actions.listMedicalRecords({petId, page: medRecCurrentPage, pageSize: MEC_RECS_PER_PAGE}))
    }

    const fetchAppointments = async () => {
        return dispatch(Appointment.actions.listAppointments({petId, page: appointmentsCurrentPage, pageSize: APPTS_PER_PAGE}))
    }

    const onViewMedRec = (medRec) => {
        navigate(mergeUrl(Urls.MEDICAL_RECORD_DETAILS, {medRecId: medRec.id}))
    }

    const onDeleteMedRec = (medRec) => {
        modals.openConfirmModal({
            title: `Are you sure you want to delete ${medRec.name}'s entry?`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            onConfirm: async () => {
                await dispatch(MedicalRecord.actions.deleteMedicalRecord(medRec.id))
                dispatch(MedicalRecord.actions.listMedicalRecords())
            }
        });
    }


    const onOpenMasterProblemList = (pet) => {
        navigate(mergeUrl(Urls.MASTER_PROBLEM_LIST_DETAILS, {petId: pet.id}))
    }

    const onViewPet = (pet) => {
        navigate(mergeUrl(Urls.PET_DETAILS, {petId: pet.id}))
    }

    const onEditPet = (pet) => {
        navigate(mergeUrl(Urls.EDIT_PET, {petId: pet.id}))
    }

    const onDeletePet = (pet) => {
        modals.openConfirmModal({
            title: `Are you sure you want to delete ${pet.name}'s entry?`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            onConfirm: async () => {
                await dispatch(Pet.actions.deletePet(pet.id))
                await dispatch(Pet.actions.listPets())
                navigate(Urls.HOME)
            }
        });
    }

    if (loading && _.isEmpty(pet)) {
        return <div><Loader /></div>
    }

    if (error) {
        return <div>
            <Title>An error happened</Title>
            <Text c={"red"}>{error}</Text>
            <Button onClick={loadData}>Retry</Button>
        </div>
    }

    if (_.isEmpty(pet)) {
        return <div>
            <Title>Pet not found</Title>
            <Text c={"red"}>The pet you are looking for does not exist</Text>
        </div>
    }

    return (
        <div className={"g-pet-details"}>
            <div className={"top-column"}>
                <PetCard pet={pet}
                         onEdit={onEditPet}
                         onDelete={onDeletePet}
                         onOpenMasterProblemList={onOpenMasterProblemList}/>

                <Card className={"medical-records-card"} shadow="sm" radius="lg" withBorder>
                    <MedicalRecordCardsList medRecs={medRecs}
                                            pets={[pet]}
                                            onView={onViewMedRec}
                                            onDelete={onDeleteMedRec}
                    />
                    <Pagination
                        mb={"xl"}
                        initialPage={medRecCurrentPage}
                        total={Math.max(medRecsSize / MEC_RECS_PER_PAGE, 1)}
                        perPage={MEC_RECS_PER_PAGE}
                        onChange={setMedRecCurrentPage} />
                </Card>

            </div>

            <Card className={"appointments-card"} shadow="sm" radius="lg" withBorder>
                <AppointmentCardsList appts={appointments}
                                      pets={[pet]}/>
                <Pagination
                    mb={"xl"}
                    initialPage={appointmentsCurrentPage}
                    total={Math.max(appointmentsSize / APPTS_PER_PAGE, 1)}
                    perPage={APPTS_PER_PAGE}
                    onChange={setAppointmentsCurrentPage} />
            </Card>


        </div>)
}