import {toNumber} from "./number";


const MPStorage = {
    USER_ID: 'MP_USER_ID',
    USER_TOKENS: 'MP_USER_TOKENS',

    getItem: key => {
        if (typeof window !== 'undefined') {
            return localStorage.getItem(key)
        }
    },
    getItemBool: key => localStorage.getItem(key)
        ? localStorage.getItem(key) === 'true'
        : null,
    getItemNumber: key => localStorage.getItem(key)
        ? toNumber(localStorage.getItem(key))
        : null,
    setItem: (key, value) => {
        localStorage.setItem(key, value)
    },
    clearItem: (key, value) => localStorage.removeItem(key),


    //AUTH STORAGE
    saveAuth: (userId, tokens) => {
        MPStorage.setItem(MPStorage.USER_ID, userId);
        MPStorage.saveTokens(tokens)
    },
    saveTokens: (tokens) => {
        MPStorage.setItem(MPStorage.USER_TOKENS, JSON.stringify(tokens));
    },
    getAccessToken: () => {
        const tokens = JSON.parse(MPStorage.getItem(MPStorage.USER_TOKENS))
        return tokens?.access?.token
    },
    getAccessTokenExpires: () => {
        const tokens = JSON.parse(MPStorage.getItem(MPStorage.USER_TOKENS))
        return tokens?.access?.expires
    },
    getTokens: () => {
        return JSON.parse(MPStorage.getItem(MPStorage.USER_TOKENS))
    },

    getUserId: () => {
        return parseInt(MPStorage.getItem(MPStorage.USER_ID));
    },
}
export default MPStorage;