import * as React from 'react';
import {useEffect} from 'react';
import {AppShell, Box, Burger, Button, Container, Group, Image, Menu, rem, Text} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {Navbar} from "./Navbar";
import {IconLogout} from "@tabler/icons-react";
import {useDispatch, useSelector} from "react-redux";
import AppState, {selectCurrentUser, selectSearchText} from "../../store/AppState";
import {createSelector} from "reselect";
import {Urls} from "../../constants/constants";
import {useNavigate} from "react-router-dom";
import User from "../../store/models/User";

const selector = createSelector(
    (state) => selectSearchText(state),
    (state) => selectCurrentUser(state),
    (searchText, user) => ({
        searchText, user
    })
)


export const PageContainer = ({page}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user} = useSelector(state => selector(state))
    const [searchInput, setSearchInput] = React.useState('');
    const [opened, {toggle}] = useDisclosure();
    const showSearch = page?.name === 'MyPetsPage'
    let timeoutId = null;

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            dispatch(AppState.actions.onSearchChange(searchInput))
        }, 1000);
    }, [searchInput]);

    const onSearchChange = (e) => {
        setSearchInput(e.currentTarget.value)
    }

    const onHomeClick = () => {
        navigate(Urls.HOME)
    }

    const onLogout = () => {
        dispatch(User.actions.logout())
    }

    return (<AppShell
        header={{height: 60}}
        navbar={{width: 220, breakpoint: 'sm', collapsed: {mobile: !opened}}}
        padding="md">
        <AppShell.Header className={"g-header"}>
            <Group h="100%" px="md" align={"center"} justify={"space-between"}>
                <Group h="100%" align={"center"} >
                    <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm"/>
                    <Image className={"mp-logo"} src={"/logo.png"} onClick={onHomeClick}/>
                    <Text className={"my-paws-title"} onClick={onHomeClick}>my paws</Text>
                </Group>
                {/*{showSearch && <TextInput ml={50}*/}
                {/*           className={"search-input"}*/}
                {/*           placeholder={"search pet"}*/}
                {/*           value={searchInput}*/}
                {/*           leftSection={<IconSearch />}*/}
                {/*           onChange={onSearchChange} />}*/}

                <Box>
                    <Menu shadow="md" width={200}>
                    <Menu.Target>
                        <Button width={200} variant={"outline"}
                                size={"sm"}>{user.email}</Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item onClick={onLogout} leftSection={<IconLogout style={{width: rem(14), height: rem(14)}}/>}>
                            Logout
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
                </Box>
            </Group>


        </AppShell.Header>
        <AppShell.Navbar p="md">
            <Navbar/>
        </AppShell.Navbar>
        <AppShell.Main><Container component={page}/></AppShell.Main>
    </AppShell>)
}
