import {useDispatch, useSelector} from "react-redux";
import Pet, {selectAllPets, selectPet} from "../../store/models/Pet";
import {createSelector} from "reselect";
import MedicalRecord, {selectMasterProblemList} from "../../store/models/MedicalRecord";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import _ from "lodash";
import {BackButton} from "../../components/BackButton";
import {Badge, Container, Divider, Group, Paper, rem, Stack, Text, Title} from "@mantine/core";
import {IconFile} from "@tabler/icons-react";


const selector = createSelector(
    (state, petId) => selectPet(state, petId),
    (state, petId) => selectMasterProblemList(state, petId),
    (pet, masterProblemList) => ({
        pet,
        masterProblemList
    })
)

export const PetMasterProblemListPage = ({...props}) => {
    const params = useParams()
    const petId = params.petId

    const dispatch = useDispatch();
    const {pet, masterProblemList} = useSelector(state => selector(state, petId))
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                await Promise.all([
                    dispatch(Pet.actions.fetchPet(petId)),
                    dispatch(MedicalRecord.actions.fetchMasterProblemList(petId))
                ])
                setLoading(false)
            } catch (e) {
                setLoading(false)
                console.log(e)
            }
        })()
    }, []);

    if (loading) {
        return <div>Loading...</div>
    }

    if (!masterProblemList) {
        return <div>
            <h1>{pet?.name}</h1>
            <div>No Master Problem list found</div>
        </div>
    }


    return (
        <Container className={"g-med-rec-details-page"}>
            <BackButton/>

            <Paper shadow={"md"} p={"xl"} radius={"lg"}>
                <Stack>
                    <Title align={"center"}>{`${pet?.name}'s master problem list`}</Title>
                    <Text mt={"50"}><b>Problem:</b> {masterProblemList?.problem}</Text>
                    <Divider/>
                    <Text><b>Medical Records:</b> {masterProblemList?.medicalRecords?.join(', ')}</Text>
                    <Divider/>
                    <Text><b>Prescriptions:</b> {masterProblemList?.prescriptions?.join(', ')}</Text>
                    <Divider/>
                    <Text><b>Exams:</b> {masterProblemList?.exams?.join(', ')}</Text>
                    <Divider/>
                    <Text><b>Labs Results:</b> {masterProblemList?.labsResults?.join(', ')}</Text>
                    <Divider/>

                </Stack>

            </Paper>
        </Container>
    )
}