import dayjs from "dayjs";

export const getYearsMonths = (birthday) => {
    const currentDate = dayjs()
    const birthdate = dayjs(birthday)
    const years = currentDate.diff(birthdate, 'year')
    const months = currentDate.diff(birthdate, 'month') - (years * 12)
    const days = currentDate.diff(birthdate, 'day') - (years * 365) - (months * 30)

    if (years === 0) return `${months}m`
    if (months === 0) return `${years}y`
    if (years === 0 && months === 0) return `${days}d`
    return `${years}y ${months}m`
}