import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Button, Group, Loader, Pagination, rem, Stack, Text, Title} from "@mantine/core";
import {IconWebhook} from "@tabler/icons-react";
import {notifications} from '@mantine/notifications';
import Appointment, {selectAppointments, selectAppointmentsSize} from "../../store/models/Appointment";
import {AppointmentCardsList} from "../../components/appointment/AppointmentCardsList";
import Pet, {selectAllPets} from "../../store/models/Pet";
import {modals} from "@mantine/modals";
import PetUserEmrLink, {selectPetUserEmrLinks} from "../../store/models/PetUserEmrLink";
import _ from "lodash";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Urls} from "../../constants/constants";
import Emr from "../../store/models/Emr";


const selector = createSelector(
    (state) => selectAppointments(state),
    (state) => selectAllPets(state),
    (state) => selectPetUserEmrLinks(state),
    (state) => selectAppointmentsSize(state),
    (appointments, pets, petUserEmrLinks, appointmentsSize) => ({
        appointments,
        pets,
        petUserEmrLinks,
        appointmentsSize
    })
)

export const MyAppointmentsPage = ({...props}) => {
    const APPOINTMENTS_PER_PAGE = 50;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = Number(queryParams.get('page')) || 1;

    const {pets, appointments, appointmentsSize, petUserEmrLinks} = useSelector(state => selector(state))
    const [searchApptLoading, setSearchApptLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(pageFromUrl);

    useEffect(() => {
        dispatch(PetUserEmrLink.actions.listLinks())
        dispatch(Pet.actions.listPets())
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchAppointments()
        navigate(`?page=${currentPage}`);
    }, [currentPage]);

    const fetchAppointments = async () => {
        try {
            const params = {}
            params.page = currentPage
            params.pageSize = APPOINTMENTS_PER_PAGE
            await dispatch(Appointment.actions.listAppointments(params))
        } catch (e) {
            console.log(e)
        }
    }
    const onSearchAppts = async (pet) => {
        setSearchApptLoading(true)

        // const result = await dispatch(Appointment.actions.getAppointmentsFromEmr(pet.id))
        const result = await dispatch(Appointment.actions.getAppointmentsFromEmr())
        await dispatch(Appointment.actions.listAppointments())
        if (result.sync_appointments > 0) {
            notifications.show({
                title: 'We found something!',
                message: `${result.sync_appointments} new appointment(s) was found.`,
            })
        } else {
            notifications.show({
                title: 'You are all caught up!',
                message: 'No new appointments were found.',
            })

        }
        setSearchApptLoading(false)
    }

    const onEditAppt = (appt) => {
        console.log(appt)
    }

    const onDeleteAppt = (appt) => {
        modals.openConfirmModal({
            title: `Are you sure you want to delete ${appt.name}'s entry?`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            onConfirm: async () => {
                await dispatch(Appointment.actions.deleteAppointment(appt.id))
                dispatch(Appointment.actions.listAppointments())
            }
        });
    }


    return (<div className="g-my-pets-page">
        <AppointmentCardsList appts={appointments}
                              pets={pets}
                              petUserEmrLinks={petUserEmrLinks}
                              onEdit={onEditAppt}
                              onDelete={onDeleteAppt}>

            {/*{_.isEmpty(pets) ?*/}
            {/*    <Text padding={"md"} radius={"md"} shadow={"sm"}>You haven't added any pets yet. Go to <Link*/}
            {/*        to={Urls.HOME}>my pets page</Link> first.</Text>*/}
            {/*    :*/}
                {/*// <Stack gap={"xs"}>{pets.map((pet, index) =>*/}
                {/*//     <Button key={index} w={rem(300)} leftSection={<IconWebhook/>} size="lg" radius={"sm"} color={"blue"}*/}
                {/*//             mb={"lg"}*/}
                {/*//             onClick={() => onSearchAppts(pet)}>sync for {pet.name}</Button>)}*/}
                {/*// </Stack>*/}
        {/*}*/}
        </AppointmentCardsList>

        <Pagination
            initialPage={currentPage}
            total={Math.max(appointmentsSize / APPOINTMENTS_PER_PAGE, 1)}
            perPage={APPOINTMENTS_PER_PAGE}
            onChange={setCurrentPage} />


        <Title size={"h3"} mt={"xl"} mb={"md"}>import appointments</Title>
        <Button w={rem(300)} leftSection={<IconWebhook/>} size="lg" radius={"sm"} color={"blue"}
                mb={"lg"}
                loading={searchApptLoading}
                onClick={onSearchAppts}>sync appointments</Button>

    </div>)
}