import {PetCard} from "../components/pet/PetCard";
import React, {useState} from "react";
import {Button, Collapse, Container, Group, SegmentedControl, Stack, Switch, Text, Title} from "@mantine/core";
import {PreviewNewPetProgress} from "../components/import/PreviewNewPetProgress";
import Pet from "../store/models/Pet";
import {notifications} from "@mantine/notifications";
import {useDispatch} from "react-redux";
import {modals} from "@mantine/modals";

export const PreviewUpdatePetModal = ({pets, index, onNextPet}) => {

    const dispatch = useDispatch()
    const petMeta = pets[index]
    const {currentPetData, newPetData, userEmrLink} = petMeta
    const [updatedPetData, setUpdatedPetData] = useState(currentPetData)
    const [previewOpened, setPreviewOpened] = useState(false)

    const onChangeAttribute = (attribute, value) => {
        setUpdatedPetData({...updatedPetData, [attribute]: value})
    }
    const onCancel = () => {
        modals.closeAll()
        onNextPet(pets, index)
    }

    const onConfirm = async () => {
        modals.closeAll()
        await dispatch(Pet.actions.updatePet(currentPetData.id, updatedPetData))
        notifications.show({
            title: 'Success!',
            message: 'Pet updated successfully',
        })
        onNextPet(pets, index)
    }

    return (<Container>
        {pets.length > 1 && <PreviewNewPetProgress pets={pets} currentPetIndex={index}/>}
        <Title size={"h3"} align={"center"} mb="xl">{`Select the correct data for ${newPetData.name}`}</Title>

        <Stack mb={"md"}>
            {petMeta.diff.map(attribute => {
                const isAttributeChanged = currentPetData[attribute] !== updatedPetData[attribute]
                return (<Stack align={"center"} key={attribute} mb={"lg"} gap={"5"}>
                    <Text style={{textTransform: "uppercase"}} size={"sm"} fw={600} c={"grey"}>{attribute}</Text>
                    <Group w={"100%"} wrap={"no-wrap"}>
                        <Text w={"45%"} style={{textAlign: "right"}}>{currentPetData[attribute]}</Text>
                        <Switch w={"10%"} key={attribute}
                                  checked={isAttributeChanged}
                                  onChange={() =>
                                      onChangeAttribute(attribute, isAttributeChanged
                                          ? currentPetData[attribute].toString()
                                          : newPetData[attribute].toString())}/>
                        <Text w={"45%"}>{newPetData[attribute]}</Text>
                    </Group>
                </Stack>)
            })}
        </Stack>

        <Collapse in={previewOpened}>
            <PetCard pet={updatedPetData} isPreview={true}/>
        </Collapse>

        <Group mt={"xl"} mb="lg" justify={"center"}>
            <Button onClick={() => setPreviewOpened(!previewOpened)} variant={"outline"}>Preview</Button>
            <Button onClick={onCancel} color={"grey"} variant={"light"}>Cancel</Button>
            <Button onClick={onConfirm}>Update</Button>
        </Group>
    </Container>)
}
