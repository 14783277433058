import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import './style/styles.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {createAppStore} from './store/store';
import reducer from "./store/reducer";
import {createTheme, MantineProvider} from "@mantine/core";
import {mantineColors} from "./constants/mantineColors";
import dayjs from "dayjs";
import {ModalsProvider} from "@mantine/modals";
import {Notifications} from "@mantine/notifications"; // Import your Redux store
import '@mantine/notifications/styles.css';

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

let initialState = window.__STATE__ ?? {};

const store = createAppStore(reducer, initialState);

const theme = createTheme({
    primaryColor: 'mp-orange',
    colors: {
        'mp-orange': mantineColors
    },
    fontFamily: 'Carrois Gothic, sans-serif',
    headings: {fontFamily: 'Carrois Gothic, sans-serif'},
    components: {
        Button: {
            defaultProps: {
                fontWeight: 200
            }
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <MantineProvider theme={theme}>
            <ModalsProvider>
                <Notifications />
                <App/>
            </ModalsProvider>
        </MantineProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();