import React, {useEffect, useRef, useState} from "react";
import Pet, {selectAllPets, selectPetsSize} from "../../store/models/Pet";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {PetCardsList} from "../../components/pet/PetCardsList";
import {Button, Group, Loader, NativeSelect, Pagination, Paper, TextInput, Title} from "@mantine/core";
import {IconPlus, IconSearch, IconWebhook} from "@tabler/icons-react";
import {useLocation, useNavigate} from "react-router-dom";
import {Urls, UserRoles} from "../../constants/constants";
import {mergeUrl} from "../../utils/url";
import UserEmrLink from "../../store/models/UserEmrLink";
import _ from "lodash";
import {modals} from '@mantine/modals';
import {PreviewNewPetModal} from "../../modals/PreviewNewPetModal";
import {notifications} from '@mantine/notifications';
import {PreviewUpdatePetModal} from "../../modals/PreviewUpdatePetModal";
import {selectCurrentUser, selectSearchText} from "../../store/AppState";
import Emr, {selectEmrs} from "../../store/models/Emr";


const selector = createSelector(
    (state) => selectCurrentUser(state),
    (state) => selectAllPets(state),
    (state) => selectSearchText(state),
    (state) => selectPetsSize(state),
    (state) => selectEmrs(state),
    (currentUser, pets, searchText, petsSize, emrs) => {
        return ({
            currentUser,
            pets,
            searchText,
            petsSize,
            emrs
        })
    }
)

export const MyPetsPage = ({...props}) => {
    const PETS_PER_PAGE = 10;
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = Number(queryParams.get('page')) || 1;

    const {currentUser, pets, petsSize, emrs} = useSelector(state => selector(state))
    const [fetchPetLoading, setFetchPetLoading] = useState(false);
    const [searchPetLoading, setSearchPetLoading] = useState(false);
    const [searchInput, setSearchInput] = React.useState(null);
    const [currentPage, setCurrentPage] = useState(pageFromUrl);
    const [selectedEmr, setSelectedEmr] = useState('');
    const navigate = useNavigate();
    const currentPets = pets
    const canFilterEmrs = currentUser?.role === UserRoles.ADMIN || currentUser?.role === UserRoles.VET;
    let timeoutId = useRef(null);

    useEffect(() => {
        dispatch(Emr.actions.listEmrs())
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchPets()
        navigate(`?page=${currentPage}`); // update URL
    }, [currentPage, selectedEmr]);

    useEffect(() => {
        if (!_.isNil(searchInput)) {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
            timeoutId.current = setTimeout(() => {
                fetchPets(true)
                navigate(`?page=1`); // update URL
            }, 1000);
        }
    }, [searchInput]);

    const fetchPets = async (reset = false) => {
        try {
            setFetchPetLoading(true)
            const params = {}
            params.page = reset ? 1 : currentPage
            params.pageSize = PETS_PER_PAGE
            if (!_.isEmpty(searchInput)) {
                params.name = searchInput
            }
            if (!_.isEmpty(selectedEmr)) {
                params.emrId = selectedEmr
            }
            await dispatch(Pet.actions.listPets(params))
        } catch (e) {
            notifications.show({title: 'Error fetching pets'})
            console.log(e)
        }

        setFetchPetLoading(false)
    }

    const onSearchChange = (e) => {
        setSearchInput(e.currentTarget.value)
    }

    const onEmrSelected = (event) => {
        setSelectedEmr(event.target.value);
    }

    const onAddPet = () => {
        navigate(Urls.ADD_PET)
    }

    const onOpenMasterProblemList = (pet) => {
        navigate(mergeUrl(Urls.MASTER_PROBLEM_LIST_DETAILS, {petId: pet.id}))
    }

    const onViewPet = (pet) => {
        navigate(mergeUrl(Urls.PET_DETAILS, {petId: pet.id}))
    }

    const onEditPet = (pet) => {
        navigate(mergeUrl(Urls.EDIT_PET, {petId: pet.id}))
    }

    const onDeletePet = (pet) => {
        modals.openConfirmModal({
            title: `Are you sure you want to delete ${pet.name}'s entry?`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            onConfirm: async () => {
                await dispatch(Pet.actions.deletePet(pet.id))
                dispatch(Pet.actions.listPets())
            }
        });
    }

    const onSearchPets = async () => {
        setSearchPetLoading(true)

        await dispatch(UserEmrLink.actions.syncUserEmrs())
        // const petsFound = await dispatch(PetUserEmrLink.actions.searchPets())
        fetchPets()
        // if (!_.isEmpty(petsFound)) {
        //     openPreviewPetModal(petsFound, 0)
        // } else {
        //     notifications.show({
        //         title: 'You are all caught up!',
        //         message: 'If you\'re missing a pet, you can add it manually.',
        //     })
        //
        // }
        setSearchPetLoading(false)
    }

    const openPreviewPetModal = (pets, index) => {
        const pet = pets[index]
        switch (pet.type) {
            case 'new' :
                modals.open({
                    children: (<PreviewNewPetModal pets={pets} index={index} onNextPet={onNextNewPet}/>),
                });
                break;
            case 'diff' :
                modals.open({
                    children: (<PreviewUpdatePetModal pets={pets} index={index} onNextPet={onNextNewPet}/>),
                });
                break;
            default:
                break;
        }
    }

    const onNextNewPet = (pets, index) => {
        if (index < pets.length - 1) {
            openPreviewPetModal(pets, index + 1)
        }
    }

    return (<div className="g-my-pets-page">
        {fetchPetLoading && <Loader />}

        <Group mb={20} gap={"xl"} align={"center"}>
            <Title>Pets</Title>
            <TextInput className={"search-input"}
                       placeholder={"search pet"}
                       value={searchInput}
                       leftSection={<IconSearch />}
                       onChange={onSearchChange}
            />

            {canFilterEmrs && <NativeSelect
                value={selectedEmr}
                onChange={onEmrSelected}
                placeholder="Select clinic">
                <option value="">Select clinic</option>
                {emrs.map((emr, index) => (
                    <option key={index} value={emr.id}>
                        {emr.name}
                    </option>
                ))}
            </NativeSelect>}

        </Group>

        <PetCardsList pets={currentPets}
                      onView={onViewPet}
                      onEdit={onEditPet}
                      onDelete={onDeletePet}
                      onOpenMasterProblemList={onOpenMasterProblemList}>
            <Paper className={"add-pet-wrapper"} color={"grey-6"} withBorder shadow="xs" p="xl" radius={"lg"}>
                <Title size={"h3"} align={"center"} mb={"xl"}>add a new pet</Title>
                <Button leftSection={<IconWebhook/>} size="lg" radius={"lg"} color={"blue"} mb={"lg"} w={"100%"}
                        loading={searchPetLoading}
                        onClick={onSearchPets}>sync all</Button>

                <Button leftSection={<IconPlus/>} size="lg" radius={"lg"} w={"100%"} onClick={onAddPet}>
                    manual add
                </Button>
            </Paper>
        </PetCardsList>

        <Pagination
            mt={"lg"}
            initialPage={currentPage}
            total={Math.max(petsSize / PETS_PER_PAGE, 1)}
            onChange={setCurrentPage} />

    </div>)
}