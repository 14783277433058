export const Hosts = {
    API: process.env.REACT_APP_API_URL,
}
console.log(Hosts.API);
export const RequestState = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
}

export const Urls = {
    HOME: "/",
    SIGNUP: "/signup",
    SIGNIN: "/signin",
    PET_DETAILS: "/pet/:petId",
    ADD_PET: "/add-pet",
    EDIT_PET: "/pet/:petId/edit",
    APPOINTMENTS: "/appointments",
    MEDICAL_RECORDS: "/medical-records",
    MEDICAL_RECORD_DETAILS: "/medical-record/:medRecId",
    MASTER_PROBLEM_LIST_DETAILS: "/mpl/:petId",
    LAB_RESULTS: "/lab-results",
    PHARMACY: "/pharmacy",
    INSURANCE: "/insurance",
    ACCOUNTS: "/accounts",
    EMRS: "/clinics",
    ADD_EMR: "/add-emr",
    EDIT_EMR: "/emr/:emrId",
    CREATE_ACCOUNT: "/account/new",
    EDIT_ACCOUNT: "/account/:id",
    NOT_FOUND: "/not-found",
}

export const ApiUrls = {
    BASE_AUTH: '/api/auth',
    BASE_USER: '/api/users',
}

export const PetAttributes = [
    "name", "specie", "sex", "breed", "weight", "hair", "birthdate"];

export const UserRoles = {
    USER: 'USER',
    VET: 'VET',
    ADMIN: 'ADMIN',
}

export const CreateUserType = {
    SIGNUP: 'SIGNUP',
    CREATE: 'CREATE',
    EDIT: 'EDIT',
}