import {del, get, post, put,} from "../../utils/fetch";
import {RequestState} from "../../constants/constants";
import _ from "lodash";


// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
export const LIST_PETS = 'LIST_PETS';
export const FETCH_PET = 'FETCH_PET';
export const CREATE_PET = 'CREATE_PET';
export const UPDATE_PET = 'UPDATE_PET';
export const DELETE_PET = 'DELETE_PET';

// ===========================
// SELECTORS
// ===========================
export const selectAllPets = state => _.values(state.models.pets);
export const selectPet = (state, petId) => state.models.pets[petId];
export const selectPetsSize = state => state.models.petsSize;

// ===========================
// MODEL
// ===========================
const Pet = {
    actions: {

        listPets: (params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/pets",
                params: {
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: LIST_PETS,
            }).then(response => response)
        },

        fetchPet: (petId, params = {}) => async (dispatch, getState) => {
            return get({
                url: "/api/pets/:petId",
                params: {
                    petId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: FETCH_PET,
            }).then(response => response)
        },

        updatePet: (petId, body, params = {}) => async (dispatch, getState) => {
            return put({
                url: "/api/pets/:petId",
                params: {
                    petId,
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: UPDATE_PET,
            }).then(response => response)
        },

        createPet: (body, params = {}) => async (dispatch, getState) => {
            return post({
                url: "/api/pets",
                params: {
                    ...params,
                },
                body,
                dispatch,
                withAuthHeaders: true,
                action: CREATE_PET,
            }).then(response => response)
        },

        deletePet: (petId, params = {}) => async (dispatch, getState) => {
            return del({
                url: "/api/pets/:petId",
                params: {
                    petId,
                    ...params,
                },
                dispatch,
                withAuthHeaders: true,
                action: DELETE_PET,
            }).then(response => response)
        },
    },

    spec: {
        pets: {},
    },

    modelReducer: (state, type, data, action, json) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case FETCH_PET:
            case CREATE_PET:
            case UPDATE_PET:
                state = {
                    ...state,
                    pets: {
                        ...state.pets,
                        [data.id]: data,
                    }
                }
                break;
            case LIST_PETS:
                state = {
                    ...state,
                    petsSize: json.response.meta?.pagination?.record_count,
                    pets: _.keyBy(data, 'id')
                }
                break;
            default: break;
        }

        return state;
    }
}
export default Pet;
