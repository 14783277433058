import {Container, Text, Loader, Title} from "@mantine/core";
import {UserForm} from "../../components/UserForm";
import {CreateUserType} from "../../constants/constants";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import User, {selectUser} from "../../store/models/User";
import {createSelector} from "reselect";
import _ from "lodash";
import {BackButton} from "../../components/BackButton";

const selector = createSelector(
    (state, id) => selectUser(state, id),
    (user) => ({
        user
    })
)

export const EditUserPage = ({}) => {

    const dispatch = useDispatch();
    const params = useParams()
    const userId = params.id
    const {user} = useSelector(state => selector(state, userId))
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)


    useEffect(() => {
        (async () => {
            try {
                setErrorMessage(null)
                setLoading(true)
                await dispatch(User.actions.fetchUser(userId))
            } catch (e) {
                console.log(e)
                setErrorMessage(e.message)
            }
            setLoading(false)
        })()
    }, []);

    return (
        <Container className={"g-edit-account"} size={420} my={40}>
            <BackButton />
            <Title ta="center">
                Edit account
            </Title>

            {loading && <Loader />}
            {errorMessage && <Text color={"red"}>{errorMessage}</Text>}
            {!loading && _.isEmpty(errorMessage) && <UserForm user={user} createUserType={CreateUserType.EDIT} />}
        </Container>);
}